import React, { useState, useEffect } from 'react'
import styles from '../css/form.module.css';
import appStyles from '../css/App.module.css';
import Sidebar from './Sidebar';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useHref, useNavigate } from 'react-router-dom';
import { Button, Container, Group, Input, List, Text } from '@mantine/core';
const AddFacility = ({ id, setId, isSmall, setIsSmall }) => {


  const [message, setMessage] = useState('');
  const [resStatus, setResStatus] = useState(0);

  const axiosPrivate = useAxiosPrivate();
  const [file, setFile] = useState('');

  let [rows, setRows] = useState([]);

  const navigate = useNavigate();




  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;
    const getCollege = async () => {

      try {
        const response = await axiosPrivate.get(`/colleges/${id}`);
      } catch (error) {

      }
    }

    isMounted && getCollege();

    return () => {
      isMounted = false;
      controller.abort();
    }

  }, [])



  useEffect(() => {
    if (resStatus === 200) {
      setTimeout(() => {
        setMessage('');
      }, 3000)
    }
  }, [message])

  const handleSubmit = async () => {



    const formData = new FormData();
    formData.append('image', file);
    formData.append('id', id);


    try {
      const response = await axiosPrivate.post(`/colleges/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });

      setMessage('Successfully uploaded');
      setResStatus(200);
      setRows([]);
    } catch (error) {
      setResStatus(403);
      setMessage('ERROR')
    }


  }





  return (
    <Container display={'flex'}>

      <form onSubmit={(e) => e.preventDefault()} style={{ width: '70%', justifyContent: 'normal', height: '90vh' }}>

        <Container >
          <h3 style={{ width: '100%', alignSelf: 'flex-start', margin: '0' }}>Add Logo</h3>



        </Container>



        <Container display={'flex'} style={{ justifyContent: 'flex-start', width: '100%', flexDirection: 'row' }} mt={'20px'}>

          <Group mr={'10px'} display={'flex'} style={{ flexDirection: 'column', }}>
            <Text>Logo </Text>
            <Input type='file' accept='image/*' required onChange={(e) => setFile(e.currentTarget.files[0])} />
          </Group>

        </Container>

        <Container mt={'20px'}>
          <Button onClick={() => handleSubmit()}>Submit</Button>
        </Container>

        <Container>    {message}   </Container>
      </form >


    </Container>

  )
}

export default AddFacility