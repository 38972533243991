import { Button, Container, Modal, Paper, Group, Text, PasswordInput } from '@mantine/core'
import { Input } from '@mantine/core'
import { PasswordStrength } from './PasswordStrength'
import React, { useEffect, useState } from 'react'

import axios from '../api/axios'
import { useDisclosure } from '@mantine/hooks'

const Settings = () => {

    const [currentPwd, setCurrentPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');

    const [message, setMessage] = useState('');
    const [err, setErr] = useState('');

    const id = sessionStorage.getItem('id');

    const [opened, {open, close}] = useDisclosure(false);

    const [allowSumbit, setAllowSubmit] = useState(false);

    const scheme = sessionStorage.getItem('colorScheme');

    const regExes = [
        /[0-9]/, 
        /[a-z]/,
        /[A-Z]/,
        /[$&+,:;=?@#|'<>.^*()%!-]/
    ]

    useEffect(()=>{
        
        if(regExes.every(regEx => regEx.test(newPwd)) && currentPwd.length > 0 && confirmPwd.length > 0) {
            setAllowSubmit(true);
        } else {
            setAllowSubmit(false);
        }

    },[newPwd, currentPwd, confirmPwd])

    const handleChangePwd = async () => {
        setErr('');
        setMessage('');

        if(!currentPwd) {
            setErr('Please provide current password');
            return false ;
        }
        if(newPwd !== confirmPwd) {
            setErr('Passwords must match');
            return false;
        }

        try {
            const response = await axios.post('/auth/changePwd', {id, currentPwd, newPwd}, {
                headers:{
                    'Content-Type':'application/json'
                }
            });
            console.log(response.data);
            setMessage('Password updated successfully!');
            setTimeout(() => {
                sessionStorage.setItem('accessToken', '');
                window.location.reload();
            }, 1000);
        } catch (error) {
             if(error.response.status === 403) {
                setErr('Wrong password. Please try again.');
            } else {
                setErr('No response from server!');
            } 
        }
    }

    const changeColorScheme = () => {

        scheme === 'dark' ? sessionStorage.setItem('colorScheme', 'light') : sessionStorage.setItem('colorScheme', 'dark');

        window.location.reload();
    }

    return (
        <Container w={'100%'} style={{width:'100%', display:'flex'}}>
            <Button m={'10px'} w={'50%'} onClick={()=>open()}>Change Password</Button>
            <Button m={'10px'} w={'50%'} onClick={()=> changeColorScheme()}>Toggle {scheme === 'dark' ? 'light' : 'dark'} theme</Button>

            <Modal opened={opened} onClose={close}>
                <Paper w={'100%'}>
                    
                        <Container w={'100%'} mb={'20px'}>
                            
                               
                                <PasswordInput label={'Current password'} mt={'5px'} w={'80%'}  placeholder='Current Password' value={currentPwd} onChange={(e)=>setCurrentPwd(e.currentTarget.value)} />
                            
                        </Container>
                        <Container w={'100%'} mb={'20px'}>
                            
                                <PasswordStrength value={newPwd} setValue={setNewPwd} mt={'5px'} placeholder='New Password' />
                            
                        </Container>
                        <Container w={'100%'} mb={'20px'}>
                            
                                
                                <PasswordInput value={confirmPwd} onChange={(e)=>setConfirmPwd(e.currentTarget.value)} label={'Confirm password'} mt={'5px'} w={'80%'} placeholder='Confirm new Password' />
                            
                        </Container>

                        <Button disabled={!allowSumbit} ml={'20px'} onClick={()=> handleChangePwd() }>Submit</Button>
                        <Text m={'20px'} c={'red'}>{err}</Text>
                        <Text m={'20px'} c={'green'}>{message}</Text>
                </Paper>
            </Modal>
        </Container>
    )
}

export default Settings