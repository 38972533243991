import React, { useEffect, useState } from 'react'
import styles from '../css/Chart.module.css';

const Chart = ({ val, title, size }) => {
    const [timer, setTimer] = useState(0);
    const VAL = val* 10;
    const setTime = () => {
        let t = 30;

        setTimeout(() => {
            let newTime = timer + 0.1;
            t = t + 0.1;
            if (timer < VAL) {
                setTimer(Math.ceil(newTime));
            }
        }, VAL <= 25 ? 500/VAL : VAL <=50 ? 1000/VAL : VAL <=75 ? 1500/VAL : 2000/VAL  )
    }

    setTime();


    return (
        <div className={styles.container} style={{ margin:`0`, width:`${200}px`, height:`${200}px`}}>
            <h3 style={{display:`${title?.length > 0 ? 'block' : 'none'}`}}>{title}</h3>
            <div className={styles.chart} style={{  background: `conic-gradient(#52228d ${timer * 3.6}deg, #242424 0deg)`, width:`${100}px`, height:`${100}px`}} >
                <div className={styles.chartContent} style={{fontSize:`${24}px`, width:`60px`, height:`60px`}}>
                    {timer % 10 === 0 ? (timer / 10).toFixed(0) : (timer/10).toFixed(1)}
                </div>
            </div>
        </div>

    )
}

export default Chart