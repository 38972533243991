import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../css/VideoChat.module.css';
import { FaVideoSlash, FaVideo } from "react-icons/fa6";
import { MdVolumeUp, MdVolumeOff, MdCallEnd } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa6';
import VideoChatContext from '../context/VideoChatProvider';

import ChatContext from '../context/ChatProvider';
import { parseInt } from 'lodash';
const VideoChat = () => {



    const { callAccepted, endCall, calling, receivingCall, timer, handleCallUser, acceptCall, callerName, friendVideo, userVideo } = useContext(VideoChatContext);

    const { friendName, friendId } = useContext(ChatContext);

    const [isMuted, setIsMuted] = useState(false);
    const [isVideoOn, setIsVideoOn] = useState(true);

    const [stream, setStream] = useState('');




    useEffect(() => {


        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
            setStream(stream);
            userVideo.current.srcObject = stream;
        })




    }, [])


    useEffect(() => {


        return () => {
            stream && stream.getTracks().forEach(track => {
                track.stop();
            })
        }
    }, [stream])


    const handleVideoOff = (option) => {
        const videoTrack = stream.getTracks().find(track => track.kind === 'video');;
        if (option === 0) {
            videoTrack.enabled = false;
            setIsVideoOn(false);
        } else if (option === 1) {
            videoTrack.enabled = true;
            setIsVideoOn(true);
        }
    }
    const handleAudioOff = (option) => {
        const audioTrack = stream.getTracks().find(track => track.kind === 'audio');;
        if (option === 0) {
            audioTrack.enabled = false;
            setIsMuted(true);
        } else if (option === 1) {
            audioTrack.enabled = true;
            setIsMuted(false);
        }
    }
    return (
        <div className={styles.modal}>
            <div className={styles['modal-content']}>

               




                <div className={styles.videosContainer}>
                <div className={styles.upperControls}>
                    {
                        (calling && !callAccepted) ?

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                Calling {friendName}...
                            </div>

                            : (!callAccepted && !receivingCall) ?

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    Call {friendName} <FaPhone className={styles.acceptControl} onClick={() => handleCallUser(stream, friendId )} />
                                </div>

                                : (callAccepted && calling) ? 
                                
                                <div style={{ display: 'flex', flexDirection: 'row', width:'100%', marginLeft:'10px',  alignItems: 'flex-start' }}>
                                    {friendName} {new Date(timer*1000).toISOString().slice( timer < 3600 ? 14 : 11 ,19)
                                    }
                                </div> : null
                    }


                </div>

                <div className={styles.upperControls}>
                    {(receivingCall && !callAccepted) ?

                        <div  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {callerName} is calling...
                            <FaPhone className={styles.acceptControl} onClick={() => acceptCall(stream)} />
                        </div> : (receivingCall && callAccepted) ?

                            <div style={{ display: 'flex', flexDirection: 'row', width:'100%', marginLeft:'10px',  alignItems: 'flex-start' }}>
                                {callerName} {new Date(timer*1000).toISOString().slice( timer < 3600 ? 14 : 11 ,19)}
                            </div> :
                            null


                    }
                </div>
                    <video className={ callAccepted ?  styles.friendVideo : styles.hidden } autoPlay playsInline ref={(ref) => (friendVideo.current = ref)} ></video>
                    <video autoPlay={true} muted ref={userVideo} playsInline className={callAccepted ? styles.userVideo :  styles.friendVideo}> </video>
                    <div className={styles.controls}>
                    <MdVolumeUp className={styles.control} style={{ display: isMuted ? 'block' : 'none' }} onClick={() => handleAudioOff(1)} />
                    <MdVolumeOff className={styles.control} style={{ display: isMuted ? 'none' : 'block' }} onClick={() => handleAudioOff(0)} />
                    <MdCallEnd className={styles.endcall} onClick={() => endCall(stream, friendId)} />
                    <FaVideoSlash className={styles.control} style={{ display: isVideoOn ? 'block' : 'none' }} onClick={() => handleVideoOff(0)} />
                    <FaVideo className={styles.control} style={{ display: isVideoOn ? 'none' : 'block' }} onClick={() => handleVideoOff(1)} />
                </div>
                </div>

               
            </div>

        </div>
    )
}

export default VideoChat