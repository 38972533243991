import React, { useContext } from 'react';

import styles from '../css/register.module.css';

import axios from '../api/axios';


import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import useChatContext from '../hooks/useChat';

import { FaLock } from 'react-icons/fa';
import { FaPerson } from 'react-icons/fa6';

import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';


import { getToken } from 'firebase/messaging';
import messaging from '../firebaseConfig'; // Import the messaging instance


import {
    Container,
    TextInput,
    PasswordInput,
    Text,
    Paper,
    Group,
    NativeSelect,
    Button,
    MultiSelect,
    Checkbox,
    Anchor,
    Stack,
    SegmentedControl,
    NumberInput,
} from '@mantine/core';

import countries from './countriesArray';
import { athleteStrengths } from '../utils/atheleteTraits';
import { PasswordStrength } from './PasswordStrength';
import { Positions } from './position';
import { states } from '../utils/states';
import { schools } from '../utils/schools';

import useAxiosPrivate from '../hooks/useAxiosPrivate';


const Register = ({ }) => {
    const { auth, setAuth } = useAuth();
    const LOGIN_URL = '/auth';
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [fullName, setFullName] = useState();
    const [success, setSuccess] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [msg, setMsg] = useState('');

    const [deviceToken, setDeviceToken] = useState('');

    const axiosPrivate = useAxiosPrivate();



    const [isSurvey, setIsSurvey] = useState(false);
    useEffect(() => {

        const requestPermission = async () => {
            try {
                const token = await getToken(messaging);
                console.log('FCM Token:', token);
                setDeviceToken(token);
                // Send this token to your server to associate it with the user

            } catch (error) {
                console.error('Error requesting permission or getting FCM token:', error);
            }
        };

        requestPermission();
    }, []);



    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])


    const { socket, setMyDbId, setUserName } = useChatContext();


    const [type, toggle] = useToggle(['register', 'login',]);

    const [role, setRole] = useState('Player');

    const [age, setAge] = useState(18);
    const [file, setFile] = useState();
    const [gender, setGender] = useState('');
    const [nationality, setNationality] = useState('');
    const [sport, setSport] = useState('');

    //Player values
    const [dominantSide, setDominantSide] = useState('left');
    const [position, setPosition] = useState('');
    const [hadInjuries, setHadInjuries] = useState('0');
    const [injuries, setInjuries] = useState([]);
    const [preferredStates, setPreferredStates] = useState([]);
    const [gpa, setGpa] = useState(2.0);
    const [preferredTuition, setPreferredTuition] = useState(5000);
    const [schoolOrSports, setSchoolOrSports] = useState('school');
    const [height, setHeight] = useState(170);
    const [weight, setWeight] = useState(70);
    const [strengths, setStrengths] = useState([]);
    const [weaknesses, setWeaknesses] = useState([]);




    //Coach values
    const [school, setSchool] = useState();
    const [experience, setExperience] = useState(1);
    const [leadershipQualities, setLeadershipQualities] = useState([]);
    const [coachingStyle, setCoachingStyle] = useState('');
    const [coachingStrategies, setCoachingStrategies] = useState([]);
    const [developmentType, setDevelopmentType] = useState();


    const [message, setMessage] = useState('');

    const form = useForm({
        initialValues: {
            email: '',
            name: '',
            password: '',
            terms: true,
        },

        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });



    const handleButtonAction = async () => {

        type === 'register' && setIsSurvey(true);

    }




 

    const generalQuestions = [


        {
            ui: <TextInput required label='Name' w={'300px'} placeholder='Your name' value={fullName} onChange={(e) => setFullName(e.currentTarget.value)} />
        },
        {

            ui: <NumberInput  min={21} max={100}  required label='Age' w={'300px'} placeholder='Age' value={age} onChange={(e)=> setAge(e)} />
        },


        {

            ui: <NativeSelect required label='Gender' w={'300px'} value={gender} onChange={(e) => setGender(e.currentTarget.value)} data={[
                { label: 'Select', value: '' },
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' }
            ]}>

            </NativeSelect>,
        },
        {

            ui: <NativeSelect required label='Nationality' w={'300px'} value={nationality} onChange={(e) => setNationality(e.currentTarget.value)} data={countries}>

            </NativeSelect>,
        },

        {

            ui: <NativeSelect required label='Sport' w={'300px'} value={sport} onChange={(e) => setSport(e.currentTarget.value)} data={[
                { label: 'Select', value: '' },
                { label: 'Soccer ⚽', value: 'Soccer' },
                { label: 'Basketball 🏀', value: 'Basketball' },
                { label: 'Volleyball 🏐', value: 'Volleyball' },
            ]} />
        },

        {

            ui: <TextInput required label='Profile Picture' type='file' accept='image/*' w={'300px'} onChange={(e) => setFile(e.currentTarget.files[0])} />
        },
    ]

    const playerQuestions = [

        {
            ui:
                <TextInput required label='Height (cm)' type='number' min={140} max={250} w={'150px'} value={height} onChange={(e) => setHeight(e.currentTarget.value)} placeholder='Height (cm)' />
        },
        {

            ui: (

                <TextInput required label='Weight (kg)' type='number' min={40} max={200} w={'150px'} value={weight} onChange={(e) => setWeight(e.currentTarget.value)} placeholder='Weight (kg)' />


            ),
        },
        {

            ui: <NativeSelect required label='Dominant Side' w={'300px'} value={dominantSide} onChange={(e) => setDominantSide(e.currentTarget.value)} data={[
                { label: 'Select', value: '' },
                { label: 'Left', value: 'Left' },
                { label: 'Right', value: 'Right' },

            ]} />,
        },
        {

            ui: <NativeSelect required label='Position' w={'300px'} value={position} onChange={(e) => setPosition(e.currentTarget.value)} data={Positions(sport)} />,
        },

        {

            ui: <TextInput required label='GPA' type='number' min={1} max={4} value={gpa} onChange={(e) => setGpa(e.currentTarget.value)} w={'300px'} />
        },

        {

            ui: <TextInput required label='Budget' type='number' value={preferredTuition} onChange={(e) => setPreferredTuition(e.currentTarget.value)} w={'300px'} />
        },
        {

            ui:
                <MultiSelect required label='Strengths' w={'300px'} mb={'10px'} onChange={(e) => setStrengths(e)} data={athleteStrengths} />
        },

        {

            ui: <NativeSelect required label='School or Sport Importance' data={[{ label: 'Select', value: '' }, 'School', 'Sports']} value={schoolOrSports} onChange={(e) => setSchoolOrSports(e.currentTarget.value)} w={'300px'} />
        },
        {

            ui: <MultiSelect required label='Preferred States' onChange={(e) => setPreferredStates(e)} w={'300px'} data={states} />
        },

    ];


    const coachQuestions = [


        {

            ui: <NativeSelect label='School' w={'300px'} value={school} onChange={(e) => setSchool(e.currentTarget.value)}  data={schools.sort((a,b)=> a > b ? 1 : a < b ? -1 : 0   )} />
        },
        {

            ui: <NumberInput min={1} max={30} type='number' label='Year of experience' w={'300px'}  value={experience} onChange={(e) => setExperience(e)} placeholder='Years of Experience' />,
        },

        {

            ui: <MultiSelect w={'300px'} onChange={(e) => setLeadershipQualities(e)} label='Leadership Qualities'  data={[
                'Communication',
                'Motivation',
                'Strategic Thinking',
                'Adaptability',
                'Accountability',
                'Patience',
                'Emotional Intelligence',
                'Decision-making',
                'Confidence'
                // Add more leadership qualities as needed
            ]} />,
        },
        {

            ui: <MultiSelect w={'300px'} data={['Motivator', 'Strategist', 'Both']} label='Coaching Style' onChange={(e) => setCoachingStyle(e)}  />,
        },
        {

            ui: <MultiSelect label='Coaching Strategies' data={['Mentoring', 'Instructional', 'Athlete-Centered', 'Cooperative']} onChange={(e) => setCoachingStrategies(e)} w={'300px'}  />,
        },
        {

            ui: <NativeSelect label='Development Orientation'  required data={[{ label: 'Both', value: '' }, 'Individual', 'Team']} onChange={(e) => setDevelopmentType(e.currentTarget.value)} w={'300px'}  />,
        },

        // Add more coach-specific questions as needed
    ];

    const regExes = [
        /[0-9]/,
        /[a-z]/,
        /[A-Z]/,
        /[$&+,:;=?@#|'<>.^*()%!-]/
    ]

    const validate = (pwd) => {
        regExes.forEach(regEx => {
            if (regEx.test(pwd) === false) {
                return false;
            }
        })

        if (/^\S+@\S+$/.test(user) === false) {
            return false;
        }

        return true;
    }

    const handleNextStep = () => {
        if (validate(pwd)) {
            setIsSurvey(true);
            setMessage({});
        } else {
            setMessage({ content: 'Error', color: 'red' });
        }
    }

    const handleSubmit = async (role) => {
        let info = {};
        if (role.toLowerCase() === 'player') {
            info = {

                role: role.toLowerCase(),
                gender: gender,
                nationality: nationality,
                sport: sport,
                dominantSide: dominantSide,
                position: position,
                preferredStates: preferredStates,
                schoolOrSports: schoolOrSports,
                gpa: gpa,
                preferredTuition: preferredTuition,
                height: height,
                weight: weight,
                strengths: strengths,
                weaknesses: weaknesses

            }

        } else {
            info = {
                username: user,
                role: role.toLowerCase(),
                gender: gender,
                nationality: nationality,
                sport: sport,
                school: school,
                experience: experience,
                leadershipQualities: leadershipQualities,
                coachingStrategies: coachingStrategies,
                coachingStyle: coachingStyle,

            }
        }


        const formData = new FormData();
        formData.append('image', file);
        formData.append('username', user);
        formData.append('pwd', pwd);
        formData.append('fullName', fullName);
        formData.append('age', age);
        formData.append('info', JSON.stringify(info));




        try {
            const response = await axios.post('/register', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            console.log(response.data);
            setMessage({ content: <Text w={'100%'} ta={'center'}>Welcome to CollegeCentral! <Link to={'/signin'}> Login here </Link> </Text>, color: '#52228d' });
            setSuccess(200);
        } catch (err) {
            if (err.response.status === 409) {
                setMessage({ content: 'Oops! It seems like someone is already using this email', color: '#f52222' });
            }
        }

    }

    useEffect(() => {
        console.log(user);
    }, [user])
    return (

        <Paper radius="md" p="xl" withBorder maw={isSurvey ? '75em' : '25em'} m={'auto'} /* bg={'#1b2545'} */>


            {isSurvey ?


                <form>
                    <Text color={message.color}>{message.content}</Text>
                    {role === 'Player'  && success !== 200 ?
                        <div className={styles.formContainer} style={{ justifyContent: 'space-between' }}>
                            <Stack>
                                {generalQuestions.map(q => q.ui)}
                            </Stack>
                            <Stack>
                                {playerQuestions.filter((q, index) => index < 6).map(q => q.ui)}
                            </Stack>
                            <Stack>
                                {playerQuestions.filter((q, index) => index >= 6).map(q => q.ui)}
                                <Button onClick={() => handleSubmit(role)}> Register </Button>

                            </Stack>
                        </div>
                        : role === 'Coach' && success !== 200 ?

                        <div className={styles.formContainer} style={{ justifyContent: 'space-around' }}>
                            <Stack>
                                {generalQuestions.map(q => q.ui)}
                            </Stack>
                            <Stack>
                                {coachQuestions.map(q => q.ui)}
                                <Button onClick={() => handleSubmit(role)}> Register </Button>

                            </Stack>
                            
                        </div> : <div></div>
                    } 
                </form> : 

                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack>
                        <Text w={'100%'} style={{ textAlign: 'center', display: msg.content ? 'block' : 'none' }} c={msg.color}>{msg.content}</Text>


                        <TextInput
                            required
                            label="Email"
                            placeholder="jdoe@example.domain"
                            value={user}
                            onChange={(event) => setUser(event.currentTarget.value)}
                            error={form.errors.email && 'Invalid email'}
                            radius="md"
                        />

                        <PasswordStrength value={pwd} setValue={setPwd} />

                        <SegmentedControl data={['Player', 'Coach']} onChange={(e) => setRole(e)} />

                        <Checkbox
                            label="I accept terms and conditions"
                            checked={form.values.terms}
                            onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
                        />

                    </Stack>

                    <Group justify="space-between" mt="xl">
                        <Button role='submit' radius="xl" onClick={() => handleNextStep()}>
                            Register
                        </Button>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link to={'/signin'} style={{ margin: '10px' }}>Already have an account? Login</Link>

                        </div>



                    </Group>
                </form>}
        </Paper>

    )
}

export default Register