import { RingProgress, Text, SimpleGrid, Paper, Center, Group, rem, Button, Container } from '@mantine/core';
import { IconArrowUpRight, IconArrowDownRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { BsCash } from 'react-icons/bs';
import { Navigate, useNavigate } from 'react-router-dom';
const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
  ticket: BsCash
};


export function StatsRing({profileViews, credits, role}) {

const navigate = useNavigate();

  const [data, setData] = useState([]);

  const dataPlayer = [
    { label: 'Profile views', stats: profileViews, progress: profileViews/50 * 100, color: 'teal', icon: 'up', msg: 'Free credit after 50 views' },
    { label: 'Credits', stats: credits, progress: credits > 0 ? 100 : 0, color: 'blue', icon: 'ticket', action: '/credits' },
    
  ]

  const dataCoach = [
  { label: 'Profile views', stats: profileViews, progress: profileViews/50 * 100, color: 'teal', icon: 'up', msg: 'Free credit after 50 views' },
]

useEffect(()=>{
  role === 'player' ? setData(dataPlayer) : setData(dataCoach);
},[])

  const stats =  data.map((stat) => {
    const Icon = icons[stat.icon];
    return (
      <Paper miw={'200px'}  m={'10px'} withBorder radius="md" p="xs" key={stat.label}>
        <Group >
          <RingProgress
            size={80}
            roundCaps
            thickness={8}
            sections={[{ value: stat.progress, color: stat.color }]}
            label={
              <Center>
                <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
              </Center>
            }
          />

          <div style={{display:'block'}}>
            <Text c="dimmed" size="xs" tt="uppercase" fw={700} >
              {stat.label}
            </Text>
            <Text fw={700} size="xl">
              {stat.stats} 
            </Text>
            
            {stat.msg ? 
            <Text size='sm'>
            {stat.msg}
            </Text> : 
                <Button mt={'10px'} onClick={()=>{sessionStorage.setItem('activeTab', 'Credits'); navigate(stat.action)}}>Credits</Button>
               }
          </div>
        </Group>
      </Paper>
    );
  });

  return <SimpleGrid cols={{ base: 1, sm: 2 }}>{stats}</SimpleGrid>;
}