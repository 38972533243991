export const Positions = (sport) => {

    let positions =    
    sport === 'Soccer' ?
        [
           {label:'Select a position', value:''} , 'Goalkeeper', 'Centre Back', 'Right Back', 'Left Back', 'Defensive Midfielder', 'Central Midfielder', 'Atacking Midfielder', 'Left Wing', 'Right Wing', 'Center Forward', 'False 9', 'Left Wingback', 'Right Winback'
        ] : sport === 'Basketball' ?
            [
                {label:'Select a position', value:''} ,  'Point Guard', 'Shooting Guard', 'Small Forward', 'Power Forward', 'Center'
            ] : sport === 'Volleyball' ?
            [
                {label:'Select a position', value:''} ,   'Setter', 'Outside Hitter', 'Opposite Hitter', 'Middle Blocker', 'Defensive Specialist'
            ] : ['Please pick a sport'];

    return positions

}

