import { Card, Avatar, Text, Group, Button, Image } from '@mantine/core';
import classes from '../css/UserCardImage.module.css';
import { MdBlock, MdInfo, MdInfoOutline, MdRemove, MdRemoveCircle, MdOutlinePersonAdd, MdCheck } from 'react-icons/md';



export function UserCardImage({name, role, enterChat, myDbId, userId, option, handleAcceptRequest, sentFriendRequests, handleFriendRequest, imageUrl, handleInfoModal, video}) {
    const stats = [
        { value: <MdInfoOutline className={classes.action} onClick={()=>handleInfoModal(userId)}/>, label: 'Info' },
        { value: option ==='0' ? '' : option === '1' ? 
            sentFriendRequests.includes(userId) ? <MdCheck/> : <MdOutlinePersonAdd onClick={()=> handleFriendRequest(userId)} className={classes.action}/> 
        : <MdCheck className={ option !== '2' && classes.action} onClick={()=> option !== '2' && handleAcceptRequest(userId)}/> , label: option === '0' ? '' : option === '1' ? 'Add' : option === '2' ? 'Sent' : 'Accept'},
      ];
  const items = stats.map((stat) => (
    <div key={stat.label}>
      <Text ta="center" fz="lg" fw={500}>
        {stat.value}
      </Text>
      <Text ta="center" fz="sm" c="dimmed" lh={1}>
        {stat.label}
      </Text>
    </div>
  ));

  return (
    <Card withBorder padding="xl" radius="md" className={classes.card}>
      <Card.Section
        h={140}
        style={{
          backgroundImage:
          imageUrl  ? `url(${imageUrl})` : 'url(https://collegecentralbucket.s3.amazonaws.com/Logo+2+-+256x256.png)',
            backgroundSize:'cover'
        }}
      />
     
      <Text ta="center" fz="lg" fw={500} mt="sm">
        {name}
      </Text>
      <Text ta="center" fz="sm" c="dimmed">
        {role}
      </Text>
      <Group mt="md" justify="center" gap={30}>
        {items}
      </Group>
      <Button style={{display: option === '0' ? 'initial' : 'none'}} fullWidth radius="md" mt="xl" size="md" variant="default" onClick={()=> enterChat(myDbId, userId)}>
        Message
      </Button>
    </Card>
  );
}