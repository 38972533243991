import React, { useContext, useState } from 'react'
import { Button, Card, Container, Drawer, Group, Modal, Notification, Text } from '@mantine/core'
import styles from '../css/Credits.module.css';
import { IconCash, IconHomeDollar, IconTicket } from '@tabler/icons-react';
import { BsCash } from "react-icons/bs";
import { useDisclosure } from '@mantine/hooks';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Form } from '@mantine/form';
import { FaShoppingCart } from 'react-icons/fa';
import { MdOutlineShoppingCart, MdShoppingCart } from 'react-icons/md';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import PaymentContext from '../context/PaymentProvider';
import { useLocation } from 'react-router-dom';
import { socket } from './socket';


const stripePromise =  loadStripe(/* 'pk_live_51OZlA1Ih84GqeSYquZHunq83iUYcbHfLoVWLhAgYn0T6CcrvT4TlbZ7IHg4hSboogV5aRxkbOiG2uMEuzRyTciJg00JWOX60v6' */ 'pk_test_51OZlA1Ih84GqeSYqMqEsIZqwl0pZnqfPvlzDpTRgCKHR1SB3fucLUcgK5ZZ1wPJhzketjLdWDrdtaYmbo8obs1b600e7ZqqC8m');




const Credits = () => {

   


    const {cart, setCart, setSessionId} = useContext(PaymentContext);

    const [opened, { open, close }] = useDisclosure();

    const axiosPrivate = useAxiosPrivate();

    const handleOpenModal = (name, val, price) => {
        open();
        setCart({ name: name, amount: val, price: price });
    }

    const id = sessionStorage.getItem('id');
    const handleCheckout = async () => {
        const stripe = await stripePromise;
        socket.emit('tryToCheckout');
        socket.on('checkoutAllowed', async () => {
            try {
                const response = await axiosPrivate.post(`/checkout/${id}`, cart, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                const session = response.data;
                sessionStorage.setItem('sessionId', session.id);
                const result = stripe.redirectToCheckout({
                    sessionId: session.id,
                },)
    
                if(result.error) {
                    console.log((await result).error.message)
                } else {
                    console.log(await result);
                }
                
            } catch (error) {
                console.log(error);
            }
        })
        
    }

    return (
        <Container>
            <Container>
                <h2 style={{textAlign:'center'}}>Get your credits and start talking to coaches now!</h2>
            </Container>

            <Container w={'100%'} className={styles.cardContainer} display={'flex'}>

                <Card className={styles.card}>
                    <Text className={styles.credit}> 1 <BsCash className={styles.cashIcon} /></Text>
                    <Text className={styles.plan}>Starter </Text>
                    <Text className={styles.price}>$50</Text>
                    <Button onClick={() => handleOpenModal('CollegeCentral Starter', 1, 50)}>Get 1 Credit</Button>
                </Card>

                <Card className={styles.card}>
                    <Text className={styles.credit}> 5 <BsCash className={styles.cashIcon} /></Text>
                    <Text className={styles.plan}>Pro </Text>
                    <Text className={styles.price}>$150</Text>
                    <Button onClick={() => handleOpenModal('CollegeCentral Pro', 5, 150)}>Get 5 Credits</Button>
                </Card>

                <Card className={styles.card}>
                    <Text className={styles.credit}> 10 <BsCash className={styles.cashIcon} /></Text>
                    <Text className={styles.plan}>Elite </Text>
                    <Text className={styles.price}>$200</Text>
                    <Button onClick={() => handleOpenModal('CollegeCentral Elite', 10, 200)}>Get 10 Credits</Button>
                </Card>

            </Container>
            <Drawer opened={opened} position={'right'} onClose={close} >

                <MdOutlineShoppingCart size={40} style={{ marginBottom: '20px' }} />

                <Container className={styles.cartItem} display={'flex'} style={{ justifyContent: 'space-between' }}>
                    <Text p={'10px 10px'} style={{ borderRight: '5px solid var(--mantine-color-myColors-9)' }}>{cart.name} </Text>
                    <Text p={'10px 10px'} >{cart.amount} {cart.amount === 1 ? 'Credit' : 'Credits'}</Text>
                    <Text p={'10px 10px'} >${cart.price}.00</Text>
                </Container>

                <Container mb={20}>
                    <Text mb={'20px'}>
                        Total: ${cart.price}.00
                    </Text>
                    <Button onClick={() => handleCheckout()}>Checkout</Button>
                </Container>




            </Drawer>




        </Container>
    )
}

export default Credits