import React, { useEffect } from 'react';

import { useState } from 'react';
import styles from '../css/form.module.css';
import appStyles from '../css/App.module.css';

import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

import { Button, Input, MultiSelect, TagsInput } from '@mantine/core';

import { Container, Text } from '@mantine/core';
const EditCollege = ({ id, isSmall, setIsSmall }) => {

    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    

    const axiosPrivate = useAxiosPrivate();

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [tuitionFee, setTuitionFee] = useState(0);
    const [acceptanceRate, setAcceptanceRate] = useState(0);
    const [studentPopulation, setStudentPopulation] = useState(0);
    const [campusPopulation, setCampusPopulation] = useState(0);
    const [association, setAssociation] = useState('');
    const [division, setDivision] = useState('');
    const [conference, setConference] = useState('');
    const [sports, setSports] = useState('');
    const [sportsWebsite, setSportsWebsite] = useState('');
    const [academicRanking, setAcademicRanking] = useState(0);
    const [schoolWebsite, setSchoolWebsite] = useState('');
    const [avgScholarship, setAvgScholarship] = useState(0);
    const [majors, setMajors] = useState('');
    const [twoYear, setTwoYear] = useState(false);
    const [religion, setReligion] = useState('');
    const [relInfluence, setRelInfluence] = useState(0);
    const [avgTemp, setAvgTemp] = useState(0);
    const [sunnyDays, setSunnyDays] = useState(0);
    const [rainyDays, setRainyDays] = useState(0);
    const [severeWeather, setSeverWeather] = useState(0);
    const [academicScore, setAcademicScore] = useState(0);
    const [tuitionScore, setTuitionScore] = useState(0);
    const [college, setCollege] = useState('');
    const [message, setMessage] = useState('');
    const [resStatus, setResStatus] = useState(0);
    const [oldMajors, setOldMajors] = useState([]) 
    const [oldSports, setOldSports] = useState([]) 
    useEffect(() => {
        const controller = new AbortController();
        let isMounted = true;
        const getCollege = async () => {
            try {
                const response = await axiosPrivate.get(`/colleges/${id}`);
                setCollege(response.data);
                console.log(response.data.academicScore);
            } catch (error) {

            }
        }

        isMounted && getCollege();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [])


    useEffect(() => {
        setName(college.name);
        setAddress(college.address);
        setCity(college.city);
        setState(college.state);
        setZip(college.zip);
        setAssociation(college.association);
        setDivision(college.division);
        setConference(college.conference);
        setSports(college.sports);
        setSportsWebsite(college.sportsWebsite);
        setTuitionFee(college.tuitionFee);
        setAcceptanceRate(college.acceptanceRate * 100);
        setStudentPopulation(college.studentPopulation);
        setCampusPopulation(college.campusPopulation);
        setAcademicRanking(college.academicRanking);
        setSchoolWebsite(college.schoolWebsite);
        setMajors(college.majors);
        setTwoYear(college.twoYear);
        setAvgTemp(college.avgTemp);
        setSunnyDays(college.sunnyDays);
        setRainyDays(college.rainyDays);
        setSeverWeather(college.severeWeather * 100);
        setReligion(college.religion);
        setRelInfluence(college.relInfluence);
        setAcademicScore(college.academicScore);
        setTuitionScore(college.tuitionScore);

        setOldMajors(college.majors);
        setOldSports(college.sports);

    }, [college])

    const handleSubmit = async () => {
        const tFee = parseInt(tuitionFee);
        
        const body = {
            name, address, city, zip, state, "tuitionFee": tFee, acceptanceRate, studentPopulation, campusPopulation, association, division, conference, sports, sportsWebsite, academicRanking, schoolWebsite, avgScholarship,    majors, twoYear, religion, relInfluence, avgTemp, sunnyDays, rainyDays, severeWeather
        }

        try {
            const response = await axiosPrivate.put(`/colleges/${id}`, body);

            setResStatus(response.status)
            setMessage(response?.data?.message);


        } catch (error) {
            setResStatus(error.response.status)
            setMessage(error.response?.data)
        }
    }

    useEffect(() => {
        if (message.length > 0) {
            setTimeout(() => {
                setMessage('');
            }, 3000)
        }
    }, [message])


    useEffect(()=>{
        console.log(sports);
    },[sports])


    return (
        <Container w={'100%'} mt={'20px'}>
           

                <Container display={'flex'} mb={'20px'}>
                    <div className={styles.inputHolder}>
                        <label>Name</label>
                        <Input w={'200px'} type='text' value={name} onChange={(e) => setName(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Address</label>
                        <Input w={'200px'} type='text' value={address} onChange={(e) => setAddress(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>City</label>
                        <Input w={'200px'} type='text' value={city} onChange={(e) => setCity(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>State</label>
                        <Input w={'200px'} type='text' value={state} onChange={(e) => setState(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Zip</label>
                        <Input w={'200px'} type='text' value={zip} onChange={(e) => setZip(e.currentTarget.value)} required />
                    </div>


                </Container>

                <Container display={'flex'} mb={'20px'}>
                    <div className={styles.inputHolder}>
                        <label>Association</label>
                        <Input w={'200px'} type='text' value={association} onChange={(e) => setAssociation(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Division</label>
                        <Input w={'200px'} type='text' value={division} onChange={(e) => setDivision(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Conference</label>
                        <Input w={'200px'} type='text' value={conference} onChange={(e) => setConference(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Sports</label>
                        <MultiSelect onChange={(e)=>setSports(e)}  data={['Soccer', 'Basketball', 'Volleyball']} w={'200px'}/>
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Sports website</label>
                        <Input w={'200px'} type='text' placeholder='LINK' value={sportsWebsite} onChange={(e) => setSportsWebsite(e.currentTarget.value)} required />
                    </div>



                </Container>

                <Container display={'flex'} mb={'20px'}>
                    <div className={styles.inputHolder}>
                        <label>Tuition Fee</label>
                        <Input w={'200px'} type='number' min={0} value={tuitionFee} onChange={(e) => setTuitionFee(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Acceptance Rate %</label>
                        <Input w={'200px'} type='number' min={0} max={100} value={acceptanceRate} onChange={(e) => setAcceptanceRate(parseInt(e.currentTarget.value))} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label> Student population</label>
                        <Input w={'200px'} type='number' min={0} value={studentPopulation} onChange={(e) => setStudentPopulation(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Campus population</label>
                        <Input w={'200px'} type='number' min={0} value={campusPopulation} onChange={(e) => setCampusPopulation(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>

                    </div>


                </Container>


                <Container display={'flex'} mb={'20px'}>
                    <div className={styles.inputHolder}>
                        <label>Academic ranking</label>
                        <Input w={'200px'} type='number' min={1} max={217} value={academicRanking} onChange={(e) => setAcademicRanking(e.currentTarget.value)} required />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> school website</label>
                        <Input w={'200px'} type='text' value={schoolWebsite} onChange={(e) => setSchoolWebsite(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>Majors</label>
                        <TagsInput type='text'  clearable placeholder='press enter after every major'  onChange={(e) => setMajors(e)} required />
                    </div>
                    <div className={styles.inputHolder}>
                        <label>2 year programs</label>
                        <Input w={'200px'} type='text' placeholder='if yes type true' value={twoYear} onChange={(e) => setTwoYear(e.currentTarget.value)} required />
                    </div>
                    <div className={styles.inputHolder}>

                    </div>

                </Container>

                <Container display={'flex'} mb={'20px'}>
                    <Container display={'flex'} style={{flexDirection:'column'}}>
                        <label>Avg. Temperature</label>
                        <Input w={'200px'} type='number' min={-40} max={40} value={avgTemp} onChange={(e) => setAvgTemp(e.currentTarget.value)} required />
                    </Container>
                    <Container display={'flex'} style={{flexDirection:'column'}}>
                        <label>Sunny days</label>
                        <Input w={'200px'} type='number' min={0} max={365} value={sunnyDays} onChange={(e) => setSunnyDays(e.currentTarget.value)} required />
                    </Container >
                    <Container display={'flex'} style={{flexDirection:'column'}}>
                        <label> Rainy days</label>
                        <Input w={'200px'} type='number' min={0} max={365} value={rainyDays} onChange={(e) => setRainyDays(e.currentTarget.value)} required />
                    </Container>

                    <Container display={'flex'} style={{flexDirection:'column'}}>
                        <label>Severe Weather %</label>
                        <Input w={'200px'} type='number' min={0} max={100} value={severeWeather} onChange={(e) => setSeverWeather(e.currentTarget.value)} required />
                    </Container>

                </Container>

                <Container display={'flex'} mb={'20px'}>
                    <Container display={'flex'} style={{flexDirection:'column'}}>
                        <label>Religion</label>
                        <Input w={'200px'} type='text' value={religion} onChange={(e) => setReligion(e.currentTarget.value)} required />
                    </Container>

                    <Container display={'flex'} style={{flexDirection:'column'}}>
                        <label>Religion influence </label>
                        <Input w={'200px'} type='number' min={0} max={10} value={relInfluence} onChange={(e) => setRelInfluence(e.currentTarget.value)} required />
                    </Container>
                    <Container display={'flex'} style={{flexDirection:'column'}}>

                    </Container>
                    <Container display={'flex'} style={{flexDirection:'column'}}>

                    </Container>
                    <Container display={'flex'} style={{flexDirection:'column'}}>

                    </Container>

                  
                </Container>

                <Container style={{position:'absolute', top:'4em', left:'200px', width:'220px' }}>
                        <Text>To help you out, here are broken majors and sports. You just need to fix them</Text>
                        Sports:
                        <Text w={'200px'} bg={'dark'}>{oldSports}</Text>
                        Majors:
                        <Text w={'200px'} bg={'black'} style={{wordBreak:'break-all'}}>{oldMajors}</Text>
                    </Container>

                <Text mb={'20px'} bg={resStatus === 200 ? '#176123' : resStatus === 409 ? 'red' : styles.hidden}>
                    {message}
                </Text>
            <div className={styles.controlsHolder}>
                <Button role='submit' onClick={() => handleSubmit()}>Update</Button>
            </div>
        </Container>



    )
}

export default EditCollege
