
export const enterChat =  (socket, userDbId, friendId) => {


    const chatMembers = [userDbId, friendId].sort();

    let dataObject = {
        userDbId,
        chatMembers
    };
    socket.emit('joinRoom', dataObject)
    

}