import React, { useEffect, useMemo, useRef, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

import { CollegeCard } from './CollegeCard';
import { Container, Image, Modal, Text, Table, Menu, ActionIcon, Flex, NativeSelect, Group, Input, MultiSelect, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDots, IconSearch } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import styles from '../css/Colleges.module.css';

import Chart from './Chart';
import { PlayerCard } from './PlayerCard';
import { Positions } from './position';
import countries from './countriesArray';
import { athleteStrengths } from '../utils/atheleteTraits';
import { states, statesObj } from '../utils/states';
import { socket } from './socket';
const Players = () => {


    const id = sessionStorage.getItem('id');
    const userSport = sessionStorage.getItem('sport');
    const axiosPrivate = useAxiosPrivate();

    const [players, setPlayers] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);
    const [currentImg, setCurrentImg] = useState('');
    const [currentPlayer, setCurrentPlayer] = useState({})

    const [openedProfile, setOpenedProfile] = useState(false);

    const [sortOption, setSortOption] = useState('0');
    const [filteredPlayers, setFilteredPlayers] = useState([]);

    const [search, setSearch] = useState('');
    const [searchOption, setSearchOption] = useState('0');

    const [filterOption, setFilterOption] = useState('0');
    const [filterType, setFilterType] = useState('');
    const [filterVal, setFilterVal] = useState([]);


    const filterRef = useRef(null);

    const [openedModal, setOpenedModal] = useState(false);

    const [friends, setFriends] = useState([]);
    const [possibleConnections, setPossibleConnections] = useState([]);
    const [sentFriendRequests, setSentFriendRequests] = useState([]);
    const [receivedFriendRequests, setReceivedFriendRequests] = useState([]);

    const [actionType, setActionType] = useState();

    const sorter = (a, b, val) => {
        if (val === '0') {
            return a.fullName.localeCompare(b.fullName)
        }
        if (val === '1') {
            return (-1 * a.fullName.localeCompare(b.fullName))
        }
        if (val === '2') {
            return (a.academicScore > b.academicScore ? 1 : a.academicScore < b.academicScore ? -1 : 0)
        }
        if (val === '3') {
            return -1 * (a.academicScore > b.academicScore ? 1 : a.academicScore < b.academicScore ? -1 : 0)
        }
        if (val === '4') {
            return (a.tuitionScore > b.tuitionScore ? 1 : a.tuitionScore < b.tuitionScore ? -1 : 0)
        }
        if (val === '5') {
            return -1 * (a.tuitionScore > b.tuitionScore ? 1 : a.tuitionScore < b.tuitionScore ? -1 : 0)
        }
    }

    const handleOpenImage = (img) => {
        open();
        setCurrentImg(img);
    }

    const handleCurrentPlayer = (player) => {
        setOpenedProfile(true);
        setCurrentPlayer(player);
    }



    useEffect(() => {

        const controller = new AbortController()
        let isMounted = true;

        const getPlayers = async () => {
            const response = await axiosPrivate.get(`/users/players/${id}`, {
                signal: controller.signal
            });
            console.log(response.data.players);
            setPlayers(response.data.players);
            setFilteredPlayers(response.data.players);
        }


        isMounted && getPlayers();

        socket.emit('getUserSocials', id);


        socket.on('userSocials', (data) => {
            setTimeout(() => {
                console.log(data);
                setFriends(data.friends);
                setPossibleConnections(data.possibleConnections);
                setSentFriendRequests(data.sentFriendRequests);
                setReceivedFriendRequests(data.receivedFriendRequests);
            }, 500)
        })

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [])



    useEffect(() => {
        if (search.length > 0 && players.length > 0) {

            if (searchOption === '0')
                setFilteredPlayers(filteredPlayers.filter(player => player.fullName.toLowerCase().includes(search.toLowerCase())));
            else if (searchOption === '1') {
                setFilteredPlayers(filteredPlayers.filter(player => player.city.toLowerCase().includes(search.toLowerCase())));
            } else if (searchOption === '2') {
                setFilteredPlayers(filteredPlayers.filter(player => player.state.toLowerCase().includes(search.toLowerCase())));

            }


        }
        else if (players.length > 0) {
            setFilteredPlayers(filteredPlayers.sort((a, b) => sorter(a, b, sortOption)));
        }

    }, [search, players, sortOption, searchOption])

    useEffect(() => {


        const handleFiltering = () => {

            switch (filterOption) {
                case '0':
                    setFilteredPlayers(players.filter(player => filterVal.includes(player.playerProfile.position)));
                    break;
                case '1':
                    setFilteredPlayers(players.filter(player => filterVal.includes(player.playerProfile.nationality)));
                    break;
                case '2':
                    const filterByAge = () => {


                        let filtered = new Map();

                        filterVal.forEach((val, index) => {
                            val = JSON.parse(val);
                            console.log(val);
                            const lower = parseInt(val.lower);
                            const upper = parseInt(val.upper);

                            players.forEach(player => {
                                const age = player.age;
                                if (age >= lower && age <= upper) {
                                    filtered.set(player._id, player)

                                }
                            })
                        })
                        setFilteredPlayers([...filtered.values()]);
                    }

                    filterByAge();
                    break;
                case '3':
                    const filterByHeight = () => {
                        let filtered = new Map();

                        filterVal.forEach((val, index) => {
                            val = JSON.parse(val);
                            console.log(val);
                            const lower = parseInt(val.lower);
                            const upper = parseInt(val.upper);

                            players.forEach(player => {
                                const height = parseInt(player.playerProfile.height);
                                if (height >= lower && height <= upper) {
                                    filtered.set(player._id, player)

                                }
                            })
                        })
                        setFilteredPlayers([...filtered.values()]);
                    }

                    filterByHeight();

                    break;

                case '4':
                    const filterByStrengths = () => {

                        let filtered = [];
                        players.forEach(player => {

                            const strengths = player.playerProfile.strengths;

                            if (strengths.some(val => filterVal.includes(val))) {

                                filtered.push(player)


                            }
                        })
                        setFilteredPlayers(filtered);
                    }

                    filterByStrengths();

                    break;

                case '5':
                    const filterByGpa = () => {

                        let filtered = new Map();

                        filterVal.forEach(val => {

                            val = JSON.parse(val);

                            const lower = parseFloat(val.lower).toFixed(1);
                            const upper = parseFloat(val.upper).toFixed(1);

                            players.forEach(player => {

                                const gpa = player.playerProfile.gpa;

                                if (gpa >= lower && gpa <= upper) {
                                    filtered.set(player._id, player);
                                }

                            })

                        })

                        setFilteredPlayers([...filtered.values()]);

                    }

                    filterByGpa();
                    break;

                case '6':

                    setFilteredPlayers(players.filter(player => filterVal.includes(player.playerProfile.dominantSide)));

                    break;

                case '7':
                    const filterByStates = () => {

                        let filtered = new Map();

                        players.forEach(player => {
                            const states = player.playerProfile.preferredStates;

                            if (states.some(state => filterVal.includes(state))) {
                                filtered.set(player._id, player);
                            }
                        })

                        setFilteredPlayers([...filtered.values()]);
                    }

                    filterByStates();
                    break;
            }

        }

        if (filterVal.length > 0) {
            handleFiltering();
        } else {
            setFilteredPlayers(players);
        }

    }, [filterVal])


    useEffect(() => {

        filterVal.length > 0 && setFilterVal([]);

    }, [filterOption])


    const sendFriendRequest = (friendId) => {
        const id = sessionStorage.getItem('id');
        socket.emit('friendRequest', { userId: id, friendId: friendId });

        setOpenedModal(true);
        setActionType('sent');

    }

    const acceptFriendRequest = (friendId) => {
        socket.emit('acceptFriendRequest', { userId: id, friendId: friendId });

    }
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column',  justifyContent: 'center' }}>

            <Flex className={styles.controlsContainer} style={{ alignItems: 'center' }} >
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Text>Sort by</Text>
                    <NativeSelect title='Sort By:' data={[
                        { label: 'Name: A-Z', value: '0' },
                        { label: 'Name: Z-A', value: '1' },

                    ]} value={sortOption} onChange={(e) => setSortOption(e.target.value)} />
                </div>
                <Group align='flex-end'>
                    <div>
                        <Text>Filter by</Text>
                        <NativeSelect data={[
                            { label: 'Position', value: '0' },
                            { label: 'Nationality', value: '1' },
                            { label: 'Age', value: '2' },
                            { label: 'Height', value: '3' },
                            { label: 'Strengths', value: '4' },
                            { label: 'High School Gpa', value: '5' },
                            { label: userSport === 'Soccer' ? 'Dominant Leg' : 'Dominant Hand', value: '6' },
                            { label: 'Preferred States', value: '7' }

                        ]} onChange={(e) => setFilterOption(e.target.value)} value={filterOption} />
                    </div>



                    <div style={{ width: '200px' }}>
                        <Text>Filter value</Text>
                        <MultiSelect value={filterVal} onChange={(e) => setFilterVal((sortOption === '2' || sortOption === '3') ? JSON.stringify(e) : e)}
                            data={
                                filterOption === '0' ?
                                    Positions(userSport) :
                                    filterOption === '1' ?
                                        countries :
                                        filterOption === '2' ?
                                            [
                                                { label: '18 - 21', value: JSON.stringify({ lower: 18, upper: 21 }) },
                                                { label: '22 - 25', value: JSON.stringify({ lower: 22, upper: 25 }) }

                                            ] : filterOption === '3' ?
                                                [
                                                    { label: '160 - 170', value: JSON.stringify({ lower: 160, upper: 170 }) },

                                                    { label: '171 - 180', value: JSON.stringify({ lower: 171, upper: 180 }) },

                                                    { label: '181 - 190', value: JSON.stringify({ lower: 181, upper: 190 }) },

                                                    { label: '191 +', value: JSON.stringify({ lower: 191, upper: 1000 }) },





                                                ] :
                                                filterOption === '4' ? athleteStrengths

                                                    : filterOption === '5' ?

                                                        [
                                                            { label: '1 - 2', value: JSON.stringify({ lower: 1, upper: 2 }) },
                                                            { label: '2.1 - 3', value: JSON.stringify({ lower: 2.1, upper: 3 }) },
                                                            { label: '3.1 - 4', value: JSON.stringify({ lower: 3.1, upper: 4 }) },
                                                            { label: '4.1 + ', value: JSON.stringify({ lower: 4.1, upper: 100 }) },
                                                        ]

                                                        : filterOption === '6' ?

                                                            [
                                                                'Left', 'Right'
                                                            ] : filterOption === '7' ? Object.keys(statesObj) : []
                            } />
                    </div>



                </Group>



            </Flex>

            <div  style={{width:'100%', display:'flex', flexWrap: 'wrap', justifyContent:'center'}}> 
                {filteredPlayers && filteredPlayers.sort((a, b) => sorter(a, b, sortOption)).map(player => <PlayerCard player={player} handleCurrentPlayer={handleCurrentPlayer} images={player.images} name={player.name} profilePicture={player.logo} viewImage={handleOpenImage} />)}
            </div>

            <Modal opened={opened} onClose={close}>
                <Image src={currentImg} />
            </Modal>



            {currentPlayer.playerProfile ?
                <Modal size={'lg'} opened={openedProfile} onClose={() => setOpenedProfile(false)}>

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>

                        {possibleConnections.some(con => con.id === currentPlayer._id) ?
                            <Button onClick={() => sendFriendRequest(currentPlayer._id)} mb={'20px'} w={'50%'} style={{ alignSelf: 'center' }}>Connect</Button>

                            : friends.some(con => con.id === currentPlayer._id) ? <Text mb={'20px'} w={'50%'} ta={'center'} p={'5px'} style={{ border: '1px solid var(--mantine-color-myColors-9)' }} > Friends</Text> :

                                receivedFriendRequests.some(con => con.id === currentPlayer._id) ?

                                    <Button w={'50%'} mb={'20px'} onClick={() => acceptFriendRequest(currentPlayer._id)}>Accept</Button> : <Text mb={'20px'} w={'50%'} ta={'center'} p={'5px'} style={{ border: '1px solid var(--mantine-color-myColors-9)' }} >Request Sent</Text>
                        }


                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <Image w={'48%'} style={{ borderRadius: '10px' }} src={currentPlayer.profilePicture}></Image>

                        {currentPlayer.playerProfile.video ?
                            <video controls style={{ width: '50%', borderRadius: '10px' }} src={currentPlayer.playerProfile.video} /> :
                            <Text w={'50%'} ta={'center'}>No Highlight Video Uploaded</Text>}
                    </div>


                    <Table
                        mt={'md'}
                        withRowBorders
                        data={{


                            body: [
                                ['Name', currentPlayer.fullName],
                                ['Age', currentPlayer.age],
                                ['Nationality', currentPlayer?.playerProfile?.nationality || null],
                                ['Height | Weight', `${currentPlayer.playerProfile.height}cm | ${currentPlayer.playerProfile.weight}kg` || null],
                                ['Position', currentPlayer.playerProfile.position],
                                [userSport === 'Soccer' ? 'Dominant Leg' : 'Dominant Hand', currentPlayer.playerProfile.dominantSide],
                                ['Strengths', currentPlayer.playerProfile.strengths.join(', ')],
                                ['High School GPA', currentPlayer.playerProfile.gpa],
                                ['Prefers to play in', currentPlayer.playerProfile.preferredStates.join(', ')],
                                ['Budget', `$${currentPlayer.playerProfile.preferredTuition}`]


                            ]
                        }}
                    />
                </Modal> : <Container></Container>}



        </div>
    )
}

export default Players