import React, { useEffect, useMemo, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

import { CollegeCard } from './CollegeCard';
import { Container, Image, Modal, Text, Table, Menu, ActionIcon, Flex, NativeSelect, Group, Input } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDots, IconSearch } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import styles from '../css/Colleges.module.css';

import Chart from './Chart';
const Colleges = () => {

    const axiosPrivate = useAxiosPrivate();

    const [colleges, setColleges] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);
    const [currentImg, setCurrentImg] = useState('');
    const [currentCollege, setCurrentCollege] = useState({})

    const [openedProfile, setOpenedProfile] = useState(false);

    const [sortOption, setSortOption] = useState('0');
    const [filteredColleges, setFilteredColleges] = useState([]);

    const [search, setSearch] = useState('');
    const [searchOption, setSearchOption] = useState('0');

    const [filterOption, setFilterOption] = useState('0');
    const [filerType, setFilterType] = useState('0');
    const [filterVal, setFilterVal] = useState('');

    const sorter = (a, b, val) => {
        if (val === '0') {
            return a.name.localeCompare(b.name)
        }
        if (val === '1') {
            return (-1 * a.name.localeCompare(b.name))
        }
        if (val === '2') {
            return (a.academicScore > b.academicScore ? 1 : a.academicScore < b.academicScore ? -1 : 0)
        }
        if (val === '3') {
            return -1 * (a.academicScore > b.academicScore ? 1 : a.academicScore < b.academicScore ? -1 : 0)
        }
        if (val === '4') {
            return (a.tuitionScore > b.tuitionScore ? 1 : a.tuitionScore < b.tuitionScore ? -1 : 0)
        }
        if (val === '5') {
            return -1 * (a.tuitionScore > b.tuitionScore ? 1 : a.tuitionScore < b.tuitionScore ? -1 : 0)
        }
    }

    const handleOpenImage = (img) => {
        open();
        setCurrentImg(img);
    }

    const handleCurrentCollege = (clg) => {
        setOpenedProfile(true);
        setCurrentCollege(clg);
    }

    useEffect(() => {

        const controller = new AbortController()
        let isMounted = true;

        const getColleges = async () => {
            const response = await axiosPrivate.get('/colleges', {
                signal: controller.signal
            });
            console.log(response.data);
            setColleges(response.data);
        }


        isMounted && getColleges();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [])

    useEffect(() => {
        if (search.length > 0 && colleges.length > 0) {

            if (searchOption === '0')
                setFilteredColleges(colleges.filter(college => college.name.toLowerCase().includes(search.toLowerCase())));
            else if (searchOption === '1') {
                setFilteredColleges(colleges.filter(college => college.city.toLowerCase().includes(search.toLowerCase())));
            } else if (searchOption === '2') {
                setFilteredColleges(colleges.filter(college => college.state.toLowerCase().includes(search.toLowerCase())));

            }


        }
        else if (colleges.length > 0) {
            setFilteredColleges(colleges.sort((a, b) => sorter(a, b, sortOption)));
        }

    }, [search, colleges, sortOption, searchOption])

    useEffect(()=>{
        console.log('option', filterOption);
        console.log('type', filerType);
        console.log('val', filterVal);

        const handleFiltering = () => {
            if(filterOption === '0') {
                switch (filerType) {
                    case '0':
                        setFilteredColleges(colleges.filter(clg => clg.academicRanking === parseInt(filterVal)));
                        break;
                    case '1':
                        setFilteredColleges(colleges.filter(clg => clg.academicRanking !== parseInt(filterVal)));
                        break;
                    case '2':
                        setFilteredColleges(colleges.filter(clg => clg.academicRanking > parseInt(filterVal)));
                        break;
                    case '3':
                        setFilteredColleges(colleges.filter(clg => clg.academicRanking < parseInt(filterVal)));
                        break;
                   
                }
            } else  if (filterOption === '1') {
                switch (filerType) {
                    case '0':
                        setFilteredColleges(colleges.filter(clg => clg.academicScore === parseInt(filterVal)));
                        break;
                    case '1':
                        setFilteredColleges(colleges.filter(clg => clg.academicScore !== parseInt(filterVal)));
                        break;
                    case '2':
                        setFilteredColleges(colleges.filter(clg => clg.academicScore > parseInt(filterVal)));
                        break;
                    case '3':
                        setFilteredColleges(colleges.filter(clg => clg.academicScore < parseInt(filterVal)));
                        break;
                   
                }
            }  else if (filterOption === '2') {
                switch (filerType) {
                    case '0':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionFee === parseInt(filterVal)));
                        break;
                    case '1':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionFee !== parseInt(filterVal)));
                        break;
                    case '2':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionFee > parseInt(filterVal)));
                        break;
                    case '3':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionFee < parseInt(filterVal)));
                        break;
                   
                }
            } else if (filterOption === '3') {
                switch (filerType) {
                    case '0':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionScore === parseInt(filterVal)));
                        break;
                    case '1':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionScore !== parseInt(filterVal)));
                        break;
                    case '2':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionScore > parseInt(filterVal)));
                        break;
                    case '3':
                        setFilteredColleges(colleges.filter(clg => clg.tuitionScore < parseInt(filterVal)));
                        break;
                   
                }
            } else  if (filterOption === '4') {
                switch (filerType) {
                    case '0':
                        setFilteredColleges(colleges.filter(clg => clg.studentPopulation === parseInt(filterVal)));
                        break;
                    case '1':
                        setFilteredColleges(colleges.filter(clg => clg.studentPopulation !== parseInt(filterVal)));
                        break;
                    case '2':
                        setFilteredColleges(colleges.filter(clg => clg.studentPopulation > parseInt(filterVal)));
                        break;
                    case '3':
                        setFilteredColleges(colleges.filter(clg => clg.studentPopulation < parseInt(filterVal)));
                        break;
                   
                }
            }
        }

      filterVal && handleFiltering();  

    },[filterOption, filerType, filterVal])
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

            <Flex  className={styles.controlsContainer} >
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Text>Sort by</Text>
                    <NativeSelect title='Sort By:' data={[
                        { label: 'Name: A-Z', value: '0' },
                        { label: 'Name: Z-A', value: '1' },
                        { label: 'Academic Score: Low to High', value: '2' },
                        { label: 'Academic Score: High to Low', value: '3' },
                        { label: 'Tuition Score: Low to High', value: '4' },
                        { label: 'Tuition Score: High to Low', value: '5' }
                    ]} value={sortOption} onChange={(e) => setSortOption(e.target.value)} />
                </div>
                <Group align='flex-end'>
                    <div>
                        <Text>Filter by</Text>
                        <NativeSelect data={[
                            { label: 'Academic Ranking', value: '0' },
                            { label: 'Academic Score', value: '1' },
                            { label: 'Tuition', value: '2' },
                            { label: 'Tuition Score', value: '3' },
                            { label: 'Student Population', value: '4' }
                        ]} onChange={(e) => setFilterOption(e.target.value)} value={filterOption} />
                    </div>
                        
                    <div>
                        <Text>Filter type</Text>
                        <NativeSelect data={[
                        { label: 'Equals', value: '0' },
                        { label: 'Does not equal', value: '1' },
                        { label: 'Greater than', value: '2' },
                        { label: 'Less than', value: '3' },
                    ]} value={filerType} onChange={(e) => setFilterType(e.target.value)} />
                    </div>

                    <div>
                        <Text>Filter value</Text>
                        <Input w={'80px'} placeholder='Value' value={filterVal} onChange={(e) => setFilterVal(e.currentTarget.value)} />
                    </div>
                    

                    
                </Group>
                <Group align={'flex-end'}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <Text>Search by</Text>
                        <NativeSelect title='Sort By:' data={[
                            { label: 'Name', value: '0' },
                            { label: 'City', value: '1' },
                            { label: 'State', value: '2' },
                        ]} value={searchOption} onChange={(e) => setSearchOption(e.target.value)} />
                    </div>

                    <Input type='Search' leftSection={<IconSearch />} value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
                </Group>


            </Flex>

            {filteredColleges && filteredColleges.sort((a, b) => sorter(a, b, sortOption)).map(clg => <CollegeCard college={clg} handleCurrentCollege={handleCurrentCollege} images={clg.images} name={clg.name} logo={clg.logo} viewImage={handleOpenImage} />)}
            <Modal opened={opened} onClose={close}>
                <Image src={currentImg} />
            </Modal>



            {currentCollege ?
                <Modal size={'lg'} opened={openedProfile} onClose={() => setOpenedProfile(false)}>
                    <Container mb={'md'} display={'flex'} style={{ justifyContent: 'space-between' }} >
                        <Chart title={'Tuition score'} val={currentCollege.tuitionScore} />
                        <Chart title={'Academic score'} val={currentCollege.academicScore} />
                    </Container>

                    <Image style={{ borderRadius: '10px' }} src={currentCollege.logo}></Image>

                    <Table
                        mt={'md'}
                        withRowBorders
                        data={{
                            /*    head: ['Name', 'Address', 'City', 'State', 'Tuition Fee', 'Acceptance Rate', 
                               'Academic Ranking', 'Majors', 'Religion',  'Student population', 'Campus Population', 'Association', 'Divison', 'Conference', 'Religion Influence', 'Average Temperature', 'Sunny Days', 'Rainy Days', 'Severe Weather Likelihood', 'Tuition Score', 'Academic Score' ], */

                            body: [['Name', currentCollege.name],
                            ['Address', currentCollege.address],
                            ['City', currentCollege.city],
                            ['State', currentCollege.state],
                            ['Tuition Fee', `$ ${currentCollege.tuitionFee}`],
                            ['Acceptance Rate', `${currentCollege.acceptanceRate * 100}%`],
                            ['Academic Ranking', currentCollege.academicRanking],
                            ['Majors', <Menu position="bottom-end" shadow="sm"  >
                                <Menu.Target>
                                    <ActionIcon variant="subtle" color="gray">
                                        <IconDots />
                                    </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    {currentCollege.majors && currentCollege.majors.map(major =>
                                        <Menu.Item   >
                                            {major}
                                        </Menu.Item>)}

                                </Menu.Dropdown>
                            </Menu>],

                            ['Student Population', currentCollege.studentPopulation],
                            ['Campus Population', currentCollege.campusPopulation],
                            ['Religion', currentCollege.religion],
                            ['Religious Influence', currentCollege.relInfluence + '/10'],
                            ['School website', <Link target='_blank' to={currentCollege.schoolWebsite}>{currentCollege.schoolWebsite}</Link>],
                            ['Athletics website', <Link target='_blank' to={currentCollege.sportsWebsite}>{currentCollege.sportsWebsite}</Link>],
                            ['Association', currentCollege.association],
                            ['Division', currentCollege.division],
                            ['Conference', currentCollege.conference],
                            ['Average Temperature', `${parseInt(currentCollege.avgTemp * 1.8 + 32)} F`],
                            ['Number of sunny days', currentCollege.sunnyDays],
                            ['Number of rainy days', currentCollege.rainyDays],
                            ['Severe Weather Likelihood', currentCollege.severeWeather * 100 + '%']

                            ]
                        }}
                    />
                </Modal> : <Container></Container>}
        </div>
    )
}

export default Colleges