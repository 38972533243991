import { Card, Group, Text, Menu, ActionIcon, Image, SimpleGrid, rem } from '@mantine/core';
import { IconDots, IconEye, IconFileZip, IconTrash } from '@tabler/icons-react';
import Chart from './Chart';



export const CollegeCard = ({college, name, logo, images, viewImage, handleCurrentCollege}) => {
   
  return (
    <Card withBorder shadow="sm" radius="md"  w={'400px'} m={'10px'}>
      <Card.Section withBorder inheritPadding py="xs">
        <Group justify="space-between">
          <Text fw={500}>{name}</Text>
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots style={{ width: rem(16), height: rem(16) }} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={()=>handleCurrentCollege(college)} bg={'transparent'}>
                View Details
              </Menu.Item>
           
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Card.Section>

      <Text mt="sm" c="dimmed" size="sm">

      </Text>

      <Card.Section mt="sm">
        <Image m={'auto'} onClick={()=> viewImage(logo)}  src={logo} />
      </Card.Section>

      <Card.Section display={'flex'} style={{justifyContent:'space-between'}}>
        <Chart val={college.tuitionScore} title={'Tuition Score'} size={200}/>
        <Chart val={college.academicScore} title={'Academic Score'} size={200}/>
      </Card.Section>

      <Card.Section inheritPadding mt="sm" pb="md">
        <SimpleGrid cols={3}>
          {images.map((image) => (
            <Image onClick={()=> viewImage(image) } src={image} key={image} radius="sm" />
          ))}
        </SimpleGrid>
      </Card.Section>

      
    </Card>
  );
}