import React, { useContext } from 'react';

import styles from '../css/register.module.css';

import axios from '../api/axios';


import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import useChatContext from '../hooks/useChat';

import { FaLock } from 'react-icons/fa';
import { FaPerson } from 'react-icons/fa6';

import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';


import { getToken } from 'firebase/messaging';
import messaging from '../firebaseConfig'; // Import the messaging instance


import {
  Container,
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  NativeSelect,
  Button,
  MultiSelect,
  Checkbox,
  Anchor,
  Stack,
  SegmentedControl,
} from '@mantine/core';

import countries from './countriesArray';
import { athleteStrengths } from '../utils/atheleteTraits';
import { PasswordStrength } from './PasswordStrength';
import { Positions } from './position';
import { states } from '../utils/states';


const SignIn = ({ setIsOtp }) => {
  const { auth, setAuth } = useAuth();
  const LOGIN_URL = '/auth';
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [fullName, setFullName] = useState();
  const [success, setSuccess] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [msg, setMsg] = useState('');

  const [deviceToken, setDeviceToken] = useState('');

  const [isSurvey, setIsSurvey] = useState(false);
  useEffect(() => {

    const requestPermission = async () => {
      try {
        const token = await getToken(messaging);
        console.log('FCM Token:', token);
        setDeviceToken(token);
        // Send this token to your server to associate it with the user

      } catch (error) {
        console.error('Error requesting permission or getting FCM token:', error);
      }
    };

    requestPermission();
  }, []);



  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])


  const { socket, setMyDbId, setUserName } = useChatContext();


  const [type, toggle] = useToggle([ 'login','register', ]);

  const [role, setRole] = useState('');

  const [age, setAge] = useState(18);
  const [file, setFile] = useState();
  const [gender, setGender] = useState('');
  const [nationality, setNationality] = useState('');
  const [sport, setSport] = useState('');

  //Player values
  const [dominantSide, setDominantSide] = useState('left');
  const [position, setPosition] = useState('');
  const [hadInjuries, setHadInjuries] = useState('0');
  const [injuries, setInjuries] = useState([]);
  const [preferredStates, setPreferredStates] = useState([]);
  const [gpa, setGpa] = useState(2.0);
  const [preferredTuition, setPreferredTuition] = useState(5000);
  const [schoolOrSports, setSchoolOrSports] = useState('school');
  const [height, setHeight] = useState(170);
  const [weight, setWeight] = useState(70);
  const [strengths, setStrengths] = useState([]);
  const [weaknesses, setWeaknesses] = useState([]);



  const [schools, setSchools] = useState([]);

  //Coach values
  const [school, setSchool] = useState();
  const [experience, setExperience] = useState(1);
  const [leadershipQualities, setLeadershipQualities] = useState([]);
  const [coachingStyle, setCoachingStyle] = useState('');
  const [coachingStrategies, setCoachingStrategies] = useState([]);
  const [developmentType, setDevelopmentType] = useState();




  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      password: '',
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
    },
  });
  const handleRegister = async () => {
    const REGISTER_URL = '/register';

    try {
      const response = await axios.post(REGISTER_URL,
        JSON.stringify({ username: user, password: pwd, fullName: fullName, }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(response?.data);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser('');
      setPwd('');
      setFullName('');
      setMsg({ content: 'Registered Successfully!', color: 'green' });
    } catch (err) {
      if (!err?.response) {
        setMsg({ content: 'No server response', color: 'red' });
      } else if (err.response?.status === 409) {
        setMsg({ content: 'Username taken', color: 'red' });
      } else {
        setMsg('Registration Failed')
      }
    }
  }
  const handleSignIn = async () => {
    try {
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({ username: user, password: pwd, deviceToken: deviceToken }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      const id = response?.data?.id;
      const sentRequests = response.data.sentFriendRequests;
      const receivedRequests = response.data.receivedFriendRequests;

      console.log(response.data);
      sessionStorage.setItem('sport', response.data.sport)
      setAuth({ user, pwd, accessToken, role });




      sessionStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('accessToken', accessToken);

      sessionStorage.setItem('user', user);
      sessionStorage.setItem('role', role);
      sessionStorage.setItem('id', id);
      setMyDbId(id);
      setUserName(user);
      sessionStorage.setItem('sentRequests', sentRequests)
      sessionStorage.setItem('receivedRequests', receivedRequests);

      socket.connect();




      setUser('');
      setPwd('');
      setTimeout(() => {
        navigate(from, { replace: true });
        window.location.reload();
      }, 500)
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setMsg({ content: 'No Server Response', color: 'red' });
      } else if (err.response?.status === 400) {
        setMsg({ content: 'Missing username or password', color: 'red' });
      } else if (err.response?.status === 401) {
        setMsg('Unauthorized');
      } else {
        setMsg({ content: 'Login failed', color: 'red' });
      }
    }
  }

  const handleButtonAction = async () => {

    type === 'register' ? setIsSurvey(true) : await handleSignIn();

  }


  useEffect(() => {
    console.log(fullName);
  }, [fullName])

  useEffect(() => {
    setMsg({});
    setFullName('');
    setPwd('');
    setUser('');
  }, [type])

  const generalQuestions = [


    {
      ui: <TextInput required label='Name' w={'300px'}  placeholder='Your name' value={fullName} onChange={(e) => setFullName(e.currentTarget.value)} />
    },
    {

      ui: <TextInput  required label='Age' w={'300px'}  placeholder='Age' value={age} onChange={(e) => setAge(e.currentTarget.value)} />
    },


    {

      ui: <NativeSelect required label='Gender' w={'300px'} value={gender} onChange={(e) => setGender(e.currentTarget.value)}  data={[
        { label: 'Select', value: '' },
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' }
      ]}>

      </NativeSelect>,
    },
    {

      ui: <NativeSelect required label='Nationality' w={'300px'} value={nationality} onChange={(e) => setNationality(e.currentTarget.value)}  data={countries}>

      </NativeSelect>,
    },

    {

      ui: <NativeSelect required label='Sport' w={'300px'} value={sport} onChange={(e) => setSport(e.currentTarget.value)}  data={[
        { label: 'Select', value: '' },
        { label: 'Soccer ⚽', value: 'Soccer' },
        { label: 'Basketball 🏀', value: 'Basketball' },
        { label: 'Volleyball 🏐', value: 'Volleyball' },
      ]} />
    },

    {

      ui: <TextInput  required label='Profile Picture' type='file' accept='image/*' w={'300px'}  onChange={(e) => setFile(e.currentTarget.files[0])} />
    },
  ]

  const playerQuestions = [

    {
      ui:
      <TextInput required label='Height (kg)' type='number' min={140} max={250} w={'150px'} value={height} onChange={(e) => setHeight(e.currentTarget.value)}  placeholder='Height (cm)' />
    },
    {

      ui: (

          <TextInput required label='Width (cm)' type='number' min={40} max={200} w={'150px'} value={weight} onChange={(e) => setWeight(e.currentTarget.value)} placeholder='Weight (kg)' />
        

      ),
    },
    {

      ui: <NativeSelect required label='Dominant Side' w={'300px'} value={dominantSide} onChange={(e) => setDominantSide(e.currentTarget.value)}  data={[
        { label: 'Select', value: '' },
        { label: 'Left', value: 'Left' },
        { label: 'Right', value: 'Right' },

      ]} />,
    },
    {

      ui: <NativeSelect required label='Position'  w={'300px'} value={position} onChange={(e) => setPosition(e.currentTarget.value)}  data={Positions(sport)} />,
    },

    {

      ui: <TextInput label='GPA' type='number' min={1} max={4} value={gpa} onChange={(e) => setGpa(e.currentTarget.value)} w={'300px'}  />
    },
   
    {

      ui: <TextInput required label='Budget' type='number' value={preferredTuition} onChange={(e) => setPreferredTuition(e.currentTarget.value)} w={'300px'}  />
    },
    {

      ui:
        <MultiSelect required label='Strengths' w={'300px'} mb={'10px'} onChange={(e) => setStrengths(e)} data={athleteStrengths} />
    },

    {

      ui: <NativeSelect required label='School or Sport Importance' data={[{ label: 'Select', value: '' }, 'School', 'Sports']} value={schoolOrSports} onChange={(e) => setSchoolOrSports(e.currentTarget.value)} w={'300px'}  />
    },
    {

      ui: <MultiSelect required label='Preferred States' onChange={(e) => setPreferredStates(e)} w={'300px'}  data={states} />
    },
    
  ];
  return (

    <Paper radius="md" p="xl" withBorder maw={isSurvey ? '75em' : '25em'} m={'auto'} /* bg={'#1b2545'} */>


      {isSurvey ?


        <form>
          {role === 'Player' ?
            <div style={{ display: 'flex', justifyContent:'space-between' }}>
              <Stack>
                {generalQuestions.map(q => q.ui)}
              </Stack>
              <Stack>
                {playerQuestions.filter((q, index) => index < 6).map(q => q.ui)}
              </Stack>
              <Stack>
                {playerQuestions.filter((q, index) => index >= 6).map(q => q.ui)}
                <Button>  </Button>

              </Stack>
            </div>
            : null}
        </form> :

        <form onSubmit={(e) => e.preventDefault()}>
          <Stack>
            <Text w={'100%'} style={{ textAlign: 'center', display: msg.content ? 'block' : 'none' }} c={msg.color}>{msg.content}</Text>


            <TextInput
              required
              label="Email"
              placeholder="jdoe@example.domain"
              value={user}
              onChange={(event) => setUser(event.currentTarget.value)}
              error={form.errors.email && 'Invalid email'}
              radius="md"
            />

            {type === 'register' ?

              <PasswordStrength value={pwd} setValue={setPwd} />
              :
              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={pwd}
                onChange={(event) => setPwd(event.currentTarget.value)}
                error={form.errors.password && 'Password should include at least 6 characters'}
                radius="md"
              />}
            {type === 'register' &&

              <SegmentedControl data={['Player', 'Coach']} onChange={(e) => setRole(e)} />

            }
            {type === 'register' && (
              <Checkbox
                label="I accept terms and conditions"
                checked={form.values.terms}
                onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
              />
            )}
          </Stack>

          <Group justify="space-between" mt="xl">
            <Button role='submit' radius="xl" onClick={() => handleButtonAction()}>
              {upperFirst(type === 'register' ? 'Continue' : 'Log in')}
            </Button>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {type === 'register' ? <Link onClick={() => toggle('login')} style={{ margin: '10px' }}>Already have an account? Login</Link> : <Link to={'/register'}>Create account</Link>}

              {type === 'login' && <Link to={'/verify'}>Forgot password?</Link>}
            </div>



          </Group>
        </form>}
    </Paper>

  )
}

export default SignIn