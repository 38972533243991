import React from 'react';


import { createContext, useState } from "react";

const PaymentContext = createContext({});

export const PaymentProvider = ({children}) => {
    const [cart, setCart] = useState({name:'', amount: 0, price: 0})

    return (
        <PaymentContext.Provider value={{cart, setCart,}}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentContext;