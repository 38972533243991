import { Paper, Text, TextInput, Textarea, Button, Group, SimpleGrid, Input } from '@mantine/core';
import { ContactIconsList } from './ContactIcons';
import classes from '../css/GetInTouch.module.css';

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';



export function GetInTouch() {
    const formRef = useRef();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const [success, setSuccess] = useState();
    const sendEmail = async (e) => {
        e.preventDefault();
        console.log('trying to send');
        try {
            const response = await emailjs.sendForm('service_5798p5y', 'template_3005x6n',formRef.current, 'g0IvnU8YtysarwPvb');
            console.log(response.status, response.text);
            if(response.status === 200) {
                setEmail('');
                setMessage('');
                setName('');
                setSuccess(200);
            }
        } catch (error) {
            console.log(error);

        }

    }


    return (
        <Paper shadow="md" radius="lg">
            <div className={classes.wrapper}>
                <div className={classes.contacts} style={{ backgroundColor: 'var(--mantine-color-myColors-9)' }}>
                    <Text mb='10px' fz="lg" fw={700} className={classes.title} c="#fff">
                        Contact information
                    </Text>

                    <ContactIconsList />
                </div>
            {success ? <Text m={'sm'}>We have received your message and will reply shortly!</Text> :
                <form id='formId' ref={formRef} className={classes.form} onSubmit={sendEmail}>
                    <Text fz="lg" fw={700} className={classes.title}>
                        Get in touch
                    </Text>

                    <div className={classes.fields}>
                        <SimpleGrid cols={{ base: 1, sm: 2 }}>
                            <Input label="Your name" name='user_name' placeholder="Your name" value={name} onChange={(e)=>setName(e.currentTarget.value)} />
                            <Input label="Your email" name='user_email' placeholder="email@example.com" value={email} onChange={(e) => setEmail(e.currentTarget.value)} required />
                        </SimpleGrid>

                        <Input mt="md" label="Subject" placeholder="Subject" required />

                        <Textarea
                            mt="md"
                            label="Your message"
                            value={message}
                            onChange={(e)=>setMessage(e.currentTarget.value)}
                            name='message'
                            placeholder="Please include all relevant information"
                            minRows={3}
                        />

                        <Group justify="flex-end" mt="md">
                            <Button type="submit"  className={classes.control}>
                                Send message
                            </Button>
                        </Group>
                    </div>
                </form> }
            </div>
        </Paper>
    );
}