import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import App from './components/App';
import { ChatProvider } from './context/ChatProvider';
import { VideoChatProvider } from './context/VideoChatProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PaymentProvider } from './context/PaymentProvider';



import '@mantine/core/styles.css';

import { createTheme, darken, MantineProvider, MantineColorsTuple } from "@mantine/core";



let colorScheme = sessionStorage.getItem('colorScheme');

if(!colorScheme) {
  colorScheme = 'dark';
  sessionStorage.setItem('colorScheme', colorScheme);
}



const myColors = [
  "#f6eeff",
  "#e7daf7",
  "#cab1ea",
  "#ad86dd",
  "#9562d2",
  "#854bcb",
  "#7d3ec9",
  "#6b31b2",
  "#5f2aa0",
  "#52228d"
];
const theme = createTheme({
  colors: {
    myColors
  },
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ChatProvider>
      <VideoChatProvider>
        <GoogleOAuthProvider clientId={'1003164156544-jch3lda2aut4sm0mlr9kq4bv5ucstl2k.apps.googleusercontent.com'}>
          <AuthProvider>
            <PaymentProvider>
              <MantineProvider theme={theme} defaultColorScheme={colorScheme}>
                <App />
              </MantineProvider>
            </PaymentProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </VideoChatProvider>
    </ChatProvider>
  </BrowserRouter>
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}