import { Text, Container, ActionIcon, Group, rem, Image } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import classes from '../css/Footer.module.css';


export function Footer({setOpenedFeatures, setOpenedBanner}) {

  const data = [
    {
      title: 'About',
      links: [
        { label: 'Features', link: '', func: (e, val)=> {e.preventDefault(); setOpenedFeatures(val)} },
        { label: 'Support', link: '', func: (e, val)=> {e.preventDefault(); setOpenedBanner(val)}},
      ],
    },
    
    {
      title: 'Community',
      links: [
        { label: 'Contact us', link: '#', func: (e, val)=> {e.preventDefault(); setOpenedBanner(val)} },
      ],
    },
  ];
  

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        onClick={(event) =>(link.func(event, true))}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text mr={'30px'} className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Image src={'https://collegecentralbucket.s3.amazonaws.com/Logo-transparent.png'} size={30} />
          <Text size="xs" c="dimmed" className={classes.description}>
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text c="dimmed" size="sm">
          © 2024 CollegeCentral All rights reserved.
        </Text>

        <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandTwitter style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandYoutube style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandInstagram style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}