import React, { useEffect } from 'react';

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import collegeStyles from '../css/CollegesList.module.css';

import { MdArrowDropUp, MdEdit, MdArrowDropDown, MdSearch } from 'react-icons/md';
import { IoAdd, IoSearch } from 'react-icons/io5';
import Chart from './Chart';

import { Flex, Input, NativeSelect, ScrollArea, Table } from '@mantine/core';
import classes from '../css/TableScrollArea.module.css';
import cx from 'classnames';

const CollegesList = ({ isSmall, setIsSmall, currentId, setCurrentId }) => {

    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();

    const [scrolled, setScrolled] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const [colleges, setColleges] = useState([]);
    const [message, setMessage] = useState('');
    const [resStatus, setResStatus] = useState(0);
    const [search, setSearch] = useState('');
    const [filteredColleges, setFilteredColleges] = useState(colleges);
    const [sortOption, setSortOption] = useState(0);

    const [openModal, setOpenModal] = useState(false);

    const [rows, setRows] = useState();

    useEffect(() => {
        const controller = new AbortController();
        let isMounted = true;
        const getAllColleges = async () => {
            try {
                const response = await axiosPrivate.get('/colleges');
                console.log(response.data);
                setColleges(response.data);
            } catch (error) {
                setMessage('No response from server');
            }
        }

        isMounted && getAllColleges();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [])

    useEffect(()=>{
    }, [openModal])

    const handleEditRoute = (college) => {
         navigate(`/editcollege/${college._id}`);
        setCurrentId(college._id);
    }
    const handleFacilityRoute = (college) => {
        navigate(`/addfacility/${college._id}`);
        setCurrentId(college._id);
    }

    useEffect(() => {
        if (search.length > 0 && colleges.length > 0) {
            setFilteredColleges(colleges.filter(college => college.name.toLowerCase().includes(search.toLowerCase())))
        }
        else if (colleges.length > 0) {
            setFilteredColleges(colleges.sort((a, b) => sorter(a, b, '0')));
        }

    }, [search, colleges])

    const sorter = (a, b, val) => {
        if (val === '0') {
            return a.name.localeCompare(b.name)
        }
        if (val === '1') {
            return (-1 * a.name.localeCompare(b.name))
        }
        if (val === '2') {
            return (a.academicScore > b.academicScore ? 1 : a.academicScore < b.academicScore ? -1 : 0)
        }
        if (val === '3') {
            return -1 * (a.academicScore > b.academicScore ? 1 : a.academicScore < b.academicScore ? -1 : 0)
        }
        if (val === '4') {
            return (a.tuitionScore > b.tuitionScore ? 1 : a.tuitionScore < b.tuitionScore ? -1 : 0)
        }
        if (val === '5') {
            return -1 * (a.tuitionScore > b.tuitionScore ? 1 : a.tuitionScore < b.tuitionScore ? -1 : 0)
        }
    }

  

    useEffect(() => {
        filteredColleges && setRows(filteredColleges.sort((a, b) => sorter(a, b, sortOption)).map((row) => (
            <Table.Tr key={row.name}>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td><MdEdit /></Table.Td>
                <Table.Td><IoAdd /></Table.Td>
                <Table.Td><Chart val={row.academicScore} size={40} /></Table.Td>
                <Table.Td><Chart val={row.tuitionScore} size={40} /> </Table.Td>
            </Table.Tr>
        )))
    }, [filteredColleges])

    return (
        <div>
            <div className={collegeStyles.header}>
                <h1>Edit Colleges</h1>
                <Flex justify={'space-between'} w={'100%'}>

                    <NativeSelect id='sorter' w={'45%'} title='Sort by:' value={sortOption} size='sm' data={[
                        { label: 'Name: Ascending', value: '0' },
                        { label: 'Name: Descending', value: '1' },
                        { label: 'Academic Score: Ascending', value: '2' },
                        { label: 'Academic Score: Descending', value: '3' },
                        { label: 'Tuition Score: Ascending', value: '4' },
                        { label: 'Tuition Score: Descending', value: '5' },

                    ]} onChange={(e) => setSortOption(e.target.value)}>

                    </NativeSelect>


                    <Input leftSection={<IoSearch size={16} />} w={'45%'} size='sm' type='text' value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search by College Name...' />
                </Flex>
            </div>
            {/* <ul className={collegeStyles.tableContainer}>
                    <div key={'tableHead'} className={collegeStyles.tableHead}>

                        <div className={collegeStyles.tableCell} style={{ width: '30%' }}>
                            Name
                            <div className={collegeStyles.sortersHolder}>
                                <MdArrowDropUp className={sortOption === '0' ? collegeStyles.active : 'inactive'} onClick={() => setSortOption('0')} />
                                <MdArrowDropDown className={sortOption === '1' ? collegeStyles.active : 'inactive'} onClick={() => setSortOption('1')} />
                            </div>
                        </div>

                        <div className={collegeStyles.tableCell} style={{ width: '15%' }}>Edit College </div>
                        <div className={collegeStyles.tableCell} style={{ width: '15%' }}>Add Facility </div>
                        <div className={collegeStyles.tableCell} style={{ width: '20%' }}>
                            Academic Score
                            <div className={collegeStyles.sortersHolder}>
                                <MdArrowDropUp className={sortOption === '2' ? collegeStyles.active : 'inactive'} onClick={() => setSortOption('2')} />
                                <MdArrowDropDown className={sortOption === '3' ? collegeStyles.active : 'inactive'} onClick={() => setSortOption('3')} />
                            </div>
                        </div>
                        <div className={collegeStyles.tableCell} style={{ width: '20%' }}>
                            Tuition Score 
                            <div className={collegeStyles.sortersHolder}>
                                <MdArrowDropUp className={sortOption === '4' ? collegeStyles.active : 'inactive'} onClick={() => setSortOption('4')} /> 
                                <MdArrowDropDown  className={sortOption === '5' ? collegeStyles.active : 'inactive'}onClick={() => setSortOption('5')}/>
                            </div>
                        </div>
                    </div>
                    {filteredColleges ? filteredColleges.sort((a, b) => sorter(a, b, sortOption)).map(college =>
                        <div key={college._id} className={collegeStyles.tableRow}>
                            <div className={collegeStyles.tableCell} style={{ width: '30%', justifyContent: 'flex-start' }} >{colleges.indexOf(college) + 1}. {college.name} </div>
                            <div className={collegeStyles.tableCellAction} style={{ width: '15%' }} onClick={() => handleEditRoute(college)}> <MdEdit /> </div>
                            <div className={collegeStyles.tableCellAction} style={{ width: '15%' }} onClick={() => handleFacilityRoute(college)}> <IoAdd /> </div>
                            <div className={collegeStyles.tableCell} style={{ width: '20%' }} >
                                <Chart val={college.academicScore} title={''} size={50} />
                            </div>
                            <div className={collegeStyles.tableCell} style={{ width: '20%' }}>
                                <Chart val={college.tuitionScore} title={''} size={50} />
                            </div>

                        </div>
                    ) :
                        <div className={collegeStyles.errMsg}>
                            {message}
                        </div>
                    }
                </ul> */}

            <ScrollArea h={'40em'} pt={'xs'} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>

                <Table
                    stickyHeader stickyHeaderOffset={0} striped withTableBorder
                    className={classes.dataTb}
                    data={{
                        head: ['Name', 'Edit College', 'ADD LOGO', 'Academic Score', 'Tuition Score'],
                        body: filteredColleges.sort((a, b) => sorter(a, b, sortOption)).map((row) =>
                            [row.name, <div className={collegeStyles.tableCellAction}><MdEdit onClick={()=> handleEditRoute(row)} /></div>,<div className={collegeStyles.tableCellAction}><IoAdd onClick={()=> handleFacilityRoute(row)} /></div>, row.academicScore, row.tuitionScore]
                        )
                    }} >


                </Table>
            </ScrollArea>
        </div>


    )
}

export default CollegesList
