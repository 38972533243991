export const athleteStrengths = [
    'Speed', 'Endurance', 'Strength', 'Accuracy', 'Agility',
    'Versatility', 'Team Player', 'Leadership', 'Resilience', 'Technical Skills'
  ];
  
export  const athleteWeaknesses = [
    'Injury-Prone', 'Consistency', 'Endurance Limitations', 'Lack of Experience',
    'Mental Toughness', 'Decision-Making', 'Communication Skills', 'Specialized Skills',
    'Adaptability', 'Overconfidence'
  ];
  