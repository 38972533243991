import axios from 'axios';

const BASE_URL = 'https://collegecentral-server.onrender.com'; 
//const BASE_URL = 'http://localhost:3500';

export default axios.create({
    baseURL:BASE_URL,
    withCredentials: true
});

export const axiosPrivate  =  axios.create({
    baseURL:BASE_URL,
    headers:{
        'Content-Type':'application/json'
    },
    withCredentials: true
});

export const axiosPrivateForm = axios.create({
    baseURL:BASE_URL,
    headers:{
        'Content-Type':'multipart/form-data'
    },
    withCredentials: true
})
