import { Title, Text, Button, Container, Group } from '@mantine/core';
import classes from '../css/NotFound.module.css';
import { Navigate } from 'react-router-dom';

 const NotFound = () => {
  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>You have found a secret place.</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
        been moved to another URL.
      </Text>
      <Group justify="center">
        <Button variant="subtle" c={'white'} size="md" onClick={()=> window.location.href = ('/getstarted')}>
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
}

export default NotFound