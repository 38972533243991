import { Text, Progress, Card, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import styles from '../css/Dashboard.module.css';
export function ProgressCard({ friends, possibleFriends }) {

  const navigate = useNavigate();

  return (
    <Card withBorder radius="md" miw={'300px'} m={'10px'} padding="xl" bg="var(--mantine-color-body)">
      <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
        Connections
      </Text>
      <Text fz="lg" fw={500}>
        {`${friends} / ${possibleFriends}`}
      </Text>
      <Progress value={friends / possibleFriends * 100} mt="md" size="lg" radius="xl" mb={'20px'} color="var(--mantine-color-myColors-9)" />
      <Button  className={styles.connectionsBtn} onClick={() => { sessionStorage.setItem('activeTab', 'Connections'); navigate('/connections') }}>Connect</Button>
    </Card>
  );
}