import React, { createContext } from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import ChatContext from './ChatProvider';
import SimplePeer from 'simple-peer';

const VideoChatContext = createContext({})

export const VideoChatProvider = ({ children }) => {


    const [stream, setStream] = useState('');
    const [friendStream, setFriendStream] = useState();

    const [calling, setCalling] = useState();
    const [callerSignal, setCallerSignal] = useState();
    const [callAccepted, setCallAccepted] = useState();
    const [caller, setCaller] = useState('');
    const [callerName, setCallerName] = useState('');
    const [receivingCall, setReceivingCall] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [connectionPeer, setConnectionPeer] = useState();
    const [isCallDeclined, setIsCallDeclined] = useState(false);
    const [friendPeerId, setFriendPeerId] = useState('');
    const [myPeerId, setMyPeerId] = useState('');
    const [iceServers, setIceServers] = useState('');

    const [me, setMe] = useState('');


    const { setIsModalOpen, socket, chatId, myDbId, userName, setFriendId } = useContext(ChatContext);

    const friendVideo = useRef();
    const userVideo = useRef();
    const connectionRef = useRef();
    const [timer, setTimer] = useState(0);
    let timeInterval;
    useEffect(() => {

      
        if(callAccepted) {
            timeInterval =  setTimeout(()=>{
                setTimer(prev => prev + 1);
            }, 1000)
        } else {
            clearTimeout(timeInterval);
        }
    }, [callAccepted, timer])

    const handleCallUser = async (stream, friendId) => {
        setCalling(true);
        setReceivingCall(false);
        setIsModalOpen(true);
        const peer = new SimplePeer({
            initiator: true,
            trickle: false,
            stream: stream,
            config: {
                iceServers: [
                    {
                        urls: 'turn:52.60.56.210:3478',
                        username:'ivan10',
                        credential:'ivan10'
                    }
                ]
            }
        });


        peer.on("signal", (data) => {
            socket.emit("callUser", {
                userToCall: friendId,
                signalData: data,
                from: myDbId,
                name: userName
            })
        })
        peer.on("stream", (stream) => {
            console.log(stream);
            friendVideo.current.srcObject = stream;

        })
        socket.on("callAccepted", (signal) => {
            console.log(signal);
            setCallAccepted(true)
            peer.signal(signal)
          
        })

        connectionRef.current = peer;
    }

    const acceptCall = (stream) => {
        setCallAccepted(true);
        setIsCallDeclined(false);
        const peer = new SimplePeer({
            initiator: false,
            trickle: false,
            stream: stream,
         
        })

        peer.on('signal', (data) => {
            console.log(data);
            socket.emit('callAccepted', { signal: data, to: caller });
        })

        peer.on('stream', stream => {
            console.log(stream);
            friendVideo.current.srcObject = stream;

        })

        peer.signal(callerSignal);

        connectionRef.current = peer;
    }

    const declineCall = () => {
        setCallAccepted(false);
        setIsCallDeclined(true);
    }

    const endCall = (stream, friendId) => {
        calling ?
            socket.emit('endCall', { friendId: friendId, calling: calling }) :
            socket.emit('endCall', { friendId: caller, calling: calling })
        setReceivingCall(false);
        setCalling(false);
        setCallEnded(true);
        setIsModalOpen(false);
        window.location.reload();

    }


    useEffect(() => {

        socket.on('connected', (id) => {
            setMe(id);
        })

        socket.on('callUser', async data => {
            setIsModalOpen(true);
            setCalling(false);
            setReceivingCall(true);
            setCallerSignal(data.signal);
            setCaller(data.from);
            setCallerName(data.name);
            setFriendId(data.from)

        })

        socket.on('callDeclined', () => {
            setIsCallDeclined(true);
            setCallAccepted(false);
            setIsModalOpen(false);
        })

        socket.on('callEnded', data => {

            setReceivingCall(false);
            setCalling(false);
            setCallEnded(true);
            setIsModalOpen(false);
            window.location.reload();

            /*   setTimeout(() => {
                  setCallEnded(true);
                  setReceivingCall(false);
                  setCalling(false);
                  setCallAccepted(false);
                  window.location.reload();
              }, 100) */
        })


    }, [])






    return (
        <VideoChatContext.Provider value={{

            stream, setStream, friendStream, setFriendStream, callerSignal, setCallerSignal, callAccepted, setCallAccepted, caller, setCaller, callerName, setCallerName, receivingCall, setReceivingCall, callEnded, setCallEnded, connectionPeer, setConnectionPeer, isCallDeclined, setIsCallDeclined,
            handleCallUser, declineCall, acceptCall, endCall, calling, setCalling, friendPeerId, friendVideo, userVideo, timer
        }}>
            {children}
        </VideoChatContext.Provider>
    )
}

export default VideoChatContext