import { ThemeIcon, Text, Title, Container, SimpleGrid, rem } from '@mantine/core';
import { IconGauge, IconCookie, IconUser, IconMessage2, IconLock, IconHeadphones } from '@tabler/icons-react';
import classes from '../css/Features.module.css';

export const MOCKDATA = [
  {
    icon: IconGauge,
    title: 'Connect',
    description:
      'The fastest way for you to connect to players/coaches!',
  },
  {
    icon: IconUser,
    title: 'Privacy focused',
    description:
      'With us, the privacy is secured. We do not even ask for your phone number!',
  },
  {
    icon: IconCookie,
    title: 'No third parties',
    description:
      'Want to chat with a coach or a player? You can do it here. Want to video chat with your connection? Same thing. This is the only place you need.',
  },
  {
    icon: IconLock,
    title: 'Lock in your future!',
    description:
      'We give you the detailed look on over 200 colleges in order to help you find the best one for yourself!',
  },
  {
    icon: IconHeadphones,
    title: '24/7 Support',
    description:
      'Contact us at any time, from any place in the world. Always here to help you.',
  },
];



export function Feature({ icon: Icon, title, description }) {
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40} color='#52228d'>
        <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </ThemeIcon>
      <Text mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" c="dimmed" lh={1.6}>
        {description}
      </Text>
    </div>
  );
}

export function FeaturesGrid() {
  const features = MOCKDATA.map((feature, index) => <Feature {...feature} key={index} />);

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>For players. For coaches. For everyone.</Title>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          With the features we provide, you will have all the necessary tool you need to find your perfect team or a player.
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={{ base: 1, sm: 2, md: 3 }}
        spacing={{ base: 'xl', md: 50 }}
        verticalSpacing={{ base: 'xl', md: 50 }}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}