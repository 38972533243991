import React from 'react';


import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const RequireAuth = () => {
    const {auth, setAuth}  = useAuth();
    const location = useLocation();
    const accessToken = sessionStorage.getItem('accessToken');
    return (
           accessToken
             ? <Outlet path='/dashboard' />
            : <Navigate to={'/getstarted'} state={{from: location}} replace/>             
    );
}

export default RequireAuth;
