import React from 'react'
import { createContext, useState, useEffect, useRef } from 'react'
import { socket } from '../components/socket'
import { debounce } from 'lodash';
import useAuth from '../hooks/useAuth';

const ChatContext = createContext({});

export const ChatProvider = ({ children }) => {

    const { auth, setAuth } = useAuth();

    const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken'));


    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState(JSON.parse(sessionStorage.getItem('messages')) || []);

    const [userName, setUserName] = useState(sessionStorage.getItem('user') || '');
    const [myDbId, setMyDbId] = useState(sessionStorage.getItem('id') || '');
    const [chatId, setChatId] = useState(sessionStorage.getItem('chatId') || '');
    const [activity, setActivity] = useState(false);

    const [friendId, setFriendId] = useState(sessionStorage.getItem('friendId') || '');
    const [friendName, setFriendName] = useState(sessionStorage.getItem('friendName') || '');

    const [isSetPicker, setIsSetPicker] = useState(false);
    const [ready, setIsReady] = useState(false);

    const [notification, setNotification] = useState({});

    const [data, setData] = useState({})

    const [socketId, setSocketId] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);





    useEffect(() => {
        data.message && setMessages([...messages, data]);
    }, [data])


    useEffect(() => {
        return () => {
            socket.emit('leave', chatId);
        }
    }, [chatId])








    useEffect(() => {

        socket.on('connect_error', (error) => {
            console.error('Connection error:', error.message);
        });


        socket.on('connected', (data) => {
            console.log("\x1b[32m",'Socket connected');
            setSocketId('socketID', data);
        });




        socket.on('chatRoom', data => {
            console.log(data);
            setChatId(data.chatId);
            setMessages(data.messages);
            setFriendName(data.friendName);
            setFriendId(data.friendId);
        })

        socket.on('rejoin', roomId => {
            socket.emit('rejoin', roomId);
        })

        socket.on('message', data => {

            setData(data.message);


        })

     
        let activityTimeout;
        socket.on('activity', data => {
            setActivity(data);

            clearTimeout(activityTimeout);
            activityTimeout = setTimeout(() => {
                setActivity('');
            }, 1000)
        });



    /*     socket.on('newFriendRequest', data => {
            setNotification(data);
            socket.emit('getUserSocials', myDbId);
            setTimeout(() => {
                setNotification({});
            }, 5000)
        }) */

        socket.on('requestAccepted', data => {
            setNotification(data);
            socket.emit('getUserSocials', myDbId);
            setTimeout(() => {
                setNotification({});
            }, 5000)
        })

    }, [])

    useEffect(() => {
        myDbId.length > 0 && socket.emit('setUserId', myDbId);

    }, [myDbId])

    useEffect(() => {
        sessionStorage.setItem('messages', JSON.stringify(messages));
    }, [messages])


    useEffect(() => {
        sessionStorage.setItem('friendId', friendId);
    }, [friendId])

    useEffect(() => {
        sessionStorage.setItem('friendName', friendName);
    }, [friendName])

    useEffect(() => {
        sessionStorage.setItem('chatId', chatId);
        socket.emit('roomRefresh', chatId);
    }, [chatId]);





    /* useEffect(() => {

        if (socket.disconnected && accessToken) {
            socket.connect()
        }
    }, [socket]) */



    useEffect(() => {
        //Notifications 
    }, [data])


    useEffect(() => {
        if (accessToken) {
            socket.connect();
        }
    }, [accessToken])


    const sendMessage = () => {

        const msg = {
            from: userName,
            message: message
        }

        socket.emit('message', { id: chatId, message: msg });
        setMessage('');
    }
    const handleActivity = debounce((val) => {
        setMessage(val);
        socket.emit('activity', { roomId: chatId, activity: true });
    })

    const onEmojiClick = (EmojiObject, event) => {

        setMessage(prev => prev + EmojiObject.emoji);
    }




    return (

        <ChatContext.Provider value={{ message, setMessage, messages, setMessages, userName, myDbId, chatId, setChatId, activity, setActivity, friendId, setFriendId, friendName, setFriendName, isSetPicker, setIsSetPicker, ready, setIsReady, notification, data, setData, sendMessage, handleActivity, onEmojiClick, setMyDbId, socket, setUserName, setNotification, isModalOpen, setIsModalOpen }}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatContext;