import React, { useContext, useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import PaymentContext from '../context/PaymentProvider'
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Text } from '@mantine/core';

import styles from '../css/PaymentSucess.module.css';

const PaymentSuccess = () => {

    const { result } = useContext(PaymentContext);
    const location = useLocation();
    const [sessionId, setSessionId] = useState(location.search.split('session_id=')[1]);
    const [credits, setCredits] = useState();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const dbId = sessionStorage.getItem('id');

    useEffect(() => {

        const controller = new AbortController();
        let isMounted = true;

        if (sessionId) {

            const updateCredits = async () => {
                try {
                    const response = await axiosPrivate.post(`checkout/success/${dbId}`, { sessionId }, { signal: controller.signal });
                    setCredits(response.data);
                    setTimeout(()=>{
                        navigate('/credits');
                    }, 5000)
                } catch (error) {
                    navigate('/dashboard');
                }

            }

            isMounted && updateCredits();



        } else {
            navigate('/dashboard');
        }

        return () => {
             isMounted = false;
            controller.abort();
        }


    }, [sessionId])

    return (
        <Container style={{display:'flex', flexDirection:'column', borderRadius:'10px', alignItems:'center', justifyContent:'center'}} >
            <Text style={{fontSize:'20px', textAlign:'center'}}>
                Your payment was successfull!
            </Text>
            <Text style={{fontSize:'20px', textAlign:'center'}}>
                You have {credits} credits left! Good Luck! 
            </Text>
            <Text style={{fontSize:'18px', textAlign:'center'}} >
                You will be redirected after 5 seconds...
            </Text>

        </Container>
    )
}

export default PaymentSuccess