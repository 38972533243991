import { initializeApp } from "firebase/app";
import { getMessaging } from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDgszA-5m4MfeJNw5QOQpHwtwcWWDKn3Bo",
    authDomain: "collegecentral-d9d93.firebaseapp.com",
    projectId: "collegecentral-d9d93",
    storageBucket: "collegecentral-d9d93.appspot.com",
    messagingSenderId: "706797194597",
    appId: "1:706797194597:web:4d6abb51802606401b658c",
    measurementId: "G-Z9SMP7V60F"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export default messaging;