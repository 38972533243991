import React, { useEffect, useState } from 'react'
import styles from '../css/Profile.module.css'

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { socket } from './socket';
import { Container, Table, Image, Group, Modal, Overlay, Text, Input, Button, NativeSelect, MultiSelect } from '@mantine/core';
import { MdEdit } from 'react-icons/md';
import { useDisclosure } from '@mantine/hooks';
import { IconCash, IconCheck, IconX } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import countries from './countriesArray';
import { Positions } from './position';
import { athleteStrengths } from '../utils/atheleteTraits';
import { states } from '../utils/states';
import { DropzoneButton } from './Dropzone';

const Profile = ({ isSmall, setIsSmall }) => {

  const [videoURL, setVideoURL] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const [currentUser, setCurrentUser] = useState();

  const [opened, { open, close }] = useDisclosure();

  const [currentEdit, setCurrentEdit] = useState(0);

  const [edits, setEdits] = useState([]);

  const [message, setMessage] = useState('');

  const [videoFile, setVideoFile] = useState();

  const [imageFile, setImageFile] = useState();


  const handleNested = (prop, newValue) => {

    const role = currentUser.role;

    if (role === 'player') {
      let playerProfile = currentUser.playerProfile;
      playerProfile[prop] = newValue;


      setCurrentUser({ ...currentUser, playerProfile });
    } else if (role === 'coach') {

      let coachProfile = currentUser.coachProfile;
      coachProfile[prop] = newValue;


      setCurrentUser({ ...currentUser, coachProfile });
    }

  }


  const id = sessionStorage.getItem('id');
  useEffect(() => {

    socket.emit('getModalInfo', {id, myId: id});
  

    socket.on('info', (data) => {
      setCurrentUser(data);
      console.log(data);
    })

  }, [])



  const handleEditData = (index) => {

    console.log(index);
    open();
    setCurrentEdit(index);


  }

  const handleSave = async () => {

    setMessage('');




    if (currentEdit === edits.length - 2) {

      try {

          const formData = new FormData();
          formData.append('video', videoFile);

          const response = await axiosPrivate.post(`/profile/${id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })

          setCurrentUser(response.data);
          setMessage('Profile updated successfully!')
          setVideoFile(null);


      } catch (error) {
        setMessage('Upload failed, please try again!');
      }


    } else if (currentEdit === edits.length - 1) {
      try {
        const formData = new FormData();
        formData.append('image', imageFile);

        const response = await axiosPrivate.post(`/profile/picture/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        console.log(response.data);
        setCurrentUser(response.data);
        setMessage('Profile updated successfully!')
        setImageFile(null);

      } catch (error) {
        setMessage('Upload failed, please try again!');

      }
    }
    else {
      try {
        socket.emit('updateInfo', currentUser);

        socket.on('info', data => {
          setCurrentUser(data);
          setMessage('Profile updated successfully!')

        })
      } catch (error) {

      }
    }


  }

  useEffect(() => {
    console.log(videoFile);
  }, [videoFile])

  useEffect(() => {

    currentUser && setEdits(currentUser.role === 'player' ? [


      <Container>
        <Text>Name</Text>
        <Input placeholder='update your name' value={currentUser.fullName} onChange={(e) => setCurrentUser({ ...currentUser, fullName: e.currentTarget.value })} />
      </Container>,
      <Container>
        <Text>Age</Text>
        <Input placeholder='update age' value={currentUser.age} onChange={(e) => setCurrentUser({ ...currentUser, age: e.currentTarget.value })} />
      </Container>,
      <Container>
        <Text>Gender</Text>
        <NativeSelect onChange={(e) => handleNested('gender', e.currentTarget.value)}
          data={[{ label: 'M', value: 'male' }, { label: 'F', value: 'female' }]} />
      </Container>,
      <Container display={'flex'}>
        <Group>
          <Text>Height</Text>
          <Input placeholder='edit height' value={currentUser.playerProfile.height} onChange={(e) => handleNested('height', e.currentTarget.value)} />
        </Group>
        <Group>
          <Text>Weight</Text>
          <Input placeholder='edit weight' value={currentUser.playerProfile.weight} onChange={(e) => handleNested('weight', e.currentTarget.value)} />
        </Group>
      </Container>,
      <Container>
        <Text>Nationality</Text>
        <NativeSelect data={countries} onChange={(e) => handleNested('nationality', e.currentTarget.value)} />
      </Container>,
      <Container>
        <Text>Position</Text>
        <NativeSelect data={Positions(currentUser.playerProfile.sport)} onChange={(e) => handleNested('position', e.currentTarget.value)} />
      </Container>,
      <Container>
        <Text>{currentUser.playerProfile.sport === 'Soccer' ? 'Dominant Leg' : 'Dominang Hand'}</Text>
        <NativeSelect data={['Select', 'Left', 'Right']} onChange={(e) => handleNested('dominantSide', e.currentTarget.value)} />
      </Container>,

      <Container>
        <Text>Strengths</Text>
        <MultiSelect data={athleteStrengths} onChange={(e) => handleNested('strengths', e)} />
      </Container>,

      <Container>
        <Text>High School GPA</Text>
        <Input type='number' min={1} max={4} value={currentUser.playerProfile.gpa} onChange={(e) => handleNested('gpa', e.currentTarget.value)} />
      </Container>,

      <Container>
        <Text>Preferred States</Text>
        <MultiSelect data={states} onChange={(e) => handleNested('preferredStates', e)} />
      </Container>,

      <Container>
        <Text>School or sports</Text>
        <NativeSelect data={['Select', 'School', 'Sports']} onChange={(e) => handleNested('schoolOrSports', e.currentTarget.value)} />
      </Container>,

      <Container>
        <Text>Budget</Text>
        <Input type='number' min={1} max={4} value={currentUser.playerProfile.preferredTuition} onChange={(e) => handleNested('preferredTuition', e.currentTarget.value)} />
      </Container>,

      <DropzoneButton type={'video'} setFile={setVideoFile} />,

      <DropzoneButton type={'image'} setFile={setImageFile} />

    ] :
      [

        <Container>
          <Text>Name</Text>
          <Input placeholder='update your name' value={currentUser.fullName} onChange={(e) => setCurrentUser({ ...currentUser, fullName: e.currentTarget.value })} />
        </Container>,
        <Container>
          <Text>Age</Text>
          <Input placeholder='update age' value={currentUser.age} onChange={(e) => setCurrentUser({ ...currentUser, age: e.currentTarget.value })} />
        </Container>,
        <Container>
          <Text>Gender</Text>
          <NativeSelect onChange={(e) => handleNested('gender', e.currentTarget.value)}
            data={[{ label: 'M', value: 'male' }, { label: 'F', value: 'female' }]} />
        </Container>,
        <Container>
          <Text>Nationality</Text>
          <NativeSelect data={countries} onChange={(e) => handleNested('nationality', e.currentTarget.value)} />
        </Container>,
        <Container>
          <Text>Experience</Text>
          <Input type='number' min={1} value={currentUser.coachProfile.experience} onChange={(e) => handleNested('experience', e.currentTarget.value)} />
        </Container>,

        <Container>
          <Text>Leadership Qualities</Text>
          <MultiSelect data={[
            'Communication',
            'Motivation',
            'Strategic Thinking',
            'Adaptability',
            'Accountability',
            'Patience',
            'Emotional Intelligence',
            'Decision-making',
            'Confidence'

          ]} onChange={(e) => handleNested('leadershipQualities', e)} />
        </Container>,

        <Container>
          <Text>Coaching Strategies	</Text>
          <MultiSelect data={['Mentoring', 'Instructional', 'Athlete-Centered', 'Cooperative']} onChange={(e) => handleNested('coachingStrategies', e)} />
        </Container>,

        <Container>
          <Text>Coaching Style</Text>
          <MultiSelect data={['Motivator', 'Strategist']} onChange={(e) => handleNested('coachingStyle', e)} />
        </Container>,


        <DropzoneButton type={'image'} setFile={setImageFile} />

      ])

  }, [currentUser])





  return (





    currentUser && currentUser.role === 'player' ?
      <Container display={'flex'} m={'0'} size={'100%'} className={styles.modalContainer}>
        <Container className={styles.mediaContainer} w={'50%'} display={'block'} style={{ justifyContent: 'space-between', marginBottom: '20px' }} >

          <div className={styles.container}>
            <Image w={'100%'} className={styles.image} mb={'20px'} style={{ borderRadius: '10px' }} src={`https://collegecentralbucket.s3.amazonaws.com/users/players/${currentUser.username}/${currentUser.profilePicture}`} />
            <div className={styles.middle}>
              <div className={styles.text} onClick={() => handleEditData(edits.length - 1)}>
                <MdEdit size={'30px'} className={styles.editPicIcon} />
              </div>
            </div>
          </div>




          <video width={'100%'} mb={'20px'} autoPlay={false} style={{ borderRadius: '10px' }} controls src={currentUser.playerProfile.video} />


        </Container>

        <Container w={'50%'} className={styles.tableContainer}>
          <Table w={'100%'}>
            <Table.Tbody>

              <Table.Tr>
                <Table.Td>Name</Table.Td>
                <Table.Td>{currentUser.fullName}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(0)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Age</Table.Td>
                <Table.Td>{currentUser.age}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(1)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Gender</Table.Td>
                <Table.Td>{currentUser.playerProfile.gender === 'male' ? 'M' : 'F'}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(2)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Height | Weight</Table.Td>
                <Table.Td>{currentUser.playerProfile.height}cm | {currentUser.playerProfile.weight}kg</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(3)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Nationality</Table.Td>
                <Table.Td>{currentUser.playerProfile.nationality}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(4)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Position</Table.Td>
                <Table.Td>{currentUser.playerProfile.position}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(5)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>{currentUser.playerProfile.sport === 'Soccer' ? 'Dominant Leg' : 'Dominang Hand'}</Table.Td>
                <Table.Td>{currentUser.playerProfile.dominantSide}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(6)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Strengths</Table.Td>
                <Table.Td>{currentUser.playerProfile.strengths.join(', ')}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(7)} /></Table.Td>
              </Table.Tr>


              <Table.Tr>
                <Table.Td>High School GPA</Table.Td>
                <Table.Td>{currentUser.playerProfile.gpa}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(8)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Prefers to play in</Table.Td>
                <Table.Td>{currentUser.playerProfile.preferredStates.join(', ')}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(9)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>School or sports</Table.Td>
                <Table.Td>{currentUser.playerProfile.schoolOrSports}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(10)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Budget</Table.Td>
                <Table.Td>${currentUser.playerProfile.preferredTuition}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(11)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Higlight Video</Table.Td>
                <Table.Td>{currentUser.playerProfile.video ? <IconCheck /> : <IconX />}</Table.Td>
                <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(12)} /></Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Credits</Table.Td>
                <Table.Td>{currentUser.credits}</Table.Td>
                <Table.Td>
                  <Link to={'/credits'} className={styles.editControl}>
                    <IconCash />
                  </Link>
                </Table.Td>
              </Table.Tr>



            </Table.Tbody>
          </Table>

          <Modal opened={opened} onClose={() => { close(); setMessage('') }}>
            {edits[currentEdit]}
            <Container mt={'20px'}>
              <Button onClick={() => handleSave()}>Save</Button>
              <Text ta={'center'} c={'var(--mantine-color-myColors-6)'} mt={'20px'}>{message}</Text>
            </Container>
          </Modal>
        </Container>

      </Container>

      : currentUser && currentUser.role === 'coach' ?

        <Container display={'flex'} m={'0'} size={'100%'} className={styles.modalContainer}>

          <Container className={styles.mediaContainer} w={'50%'} display={'block'} style={{ justifyContent: 'space-between', marginBottom: '20px' }} >


            <div className={styles.container}>
              <Image w={'100%'} mb={'20px'} style={{ borderRadius: '10px' }} src={`https://collegecentralbucket.s3.amazonaws.com/users/coaches/${currentUser.username}/${currentUser.profilePicture}`} />
              <div className={styles.middle}>
                <div className={styles.text}>
                  <MdEdit size={'30px'} className={styles.editPicIcon} onClick={() => handleEditData(edits.length - 1)} />
                </div>
              </div>
            </div>

          </Container>

          <Container w={'50%'} className={styles.tableContainer}>
            <Table w={'100%'}>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>Name</Table.Td>
                  <Table.Td>{currentUser.fullName}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(0)} /></Table.Td>

                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Age</Table.Td>
                  <Table.Td>{currentUser.age}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(1)} /></Table.Td>

                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Gender</Table.Td>
                  <Table.Td>{currentUser.coachProfile.gender === 'male' ? 'M' : 'F'}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(2)} /></Table.Td>

                </Table.Tr>

                <Table.Tr>
                  <Table.Td>Nationality</Table.Td>
                  <Table.Td>{currentUser.coachProfile.nationality}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(3)} /></Table.Td>

                </Table.Tr>



                <Table.Tr>
                  <Table.Td>Experience</Table.Td>
                  <Table.Td>{currentUser.coachProfile.experience} years</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(4)} /></Table.Td>

                </Table.Tr>


                <Table.Tr>
                  <Table.Td>Leadership Qualities</Table.Td>
                  <Table.Td>{currentUser.coachProfile.leadershipQualities.join(', ')}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(5)} /></Table.Td>

                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Coaching Strategies</Table.Td>
                  <Table.Td>{currentUser.coachProfile.coachingStrategies.join(', ')}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(6)} /></Table.Td>

                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Coaching Style</Table.Td>
                  <Table.Td>{currentUser.coachProfile.coachingStyle.join(', ')}</Table.Td>
                  <Table.Td><MdEdit className={styles.editControl} onClick={() => handleEditData(7)} /></Table.Td>

                </Table.Tr>


              </Table.Tbody>
            </Table>
            <Modal opened={opened} onClose={() => { close(); setMessage('') }}>
              {edits[currentEdit]}
              <Container mt={'20px'}>
                <Button onClick={() => handleSave()}>Save</Button>
                <Text ta={'center'} c={'var(--mantine-color-myColors-6)'} mt={'20px'}>{message}</Text>
              </Container>
            </Modal>
          </Container>



        </Container> : null



  )
}

export default Profile