import React from 'react'
import styles from '../css/Loader.module.css'
const Loader = ({ arr }) => {
  return (
    <div className={styles.loaderOutline} >
      <div className={styles.loaderProgress}>
      </div>
    </div>
  )
}

export default Loader