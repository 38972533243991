import React from 'react'
import styles from '../css/header.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../images/Logo-transparent.png';

const Header = ({accessToken}) => {
    const navigate = useNavigate();
    const {header, imageContainer, navContainer, navItem } = styles;
    return (
        <div className={header}>
            
        </div>
    )
}

export default Header
