import React, { useContext, useEffect, useState } from 'react'

import styles from '../css/Dashboard.module.css'

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Container, Text, Image, Card, Table, Button, Group } from '@mantine/core';
import { ProgressCard } from './ProgressCard';
import { StatsRing } from './StatsRing';

import { enterChat } from './handleEnterChat';

import Logo from '../images/Logo.png';
import { socket } from './socket'
import { useNavigate } from 'react-router-dom';
import ChatContext from '../context/ChatProvider';


const Dashboard = () => {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const id = sessionStorage.getItem('id');
    const role = sessionStorage.getItem('role');

    const [friends, setFriends] = useState(0);
    const [possibleFriends, setPossibleFriends] = useState(0);
    const [profileViews, setProfileViews] = useState(0);
    const [credits, setCredits] = useState(0);
    const [starredUsers, setStarredUsers] = useState([]);
    const [data, setData] = useState({});

    const handleEnterChat = (myId, friendId) => {


        enterChat(socket, myId, friendId);

        setTimeout(() => {
            navigate('/chat');
        }, 1000)

    }


    useEffect(() => {


        const getUserData = async () => {
            try {
                const response = await axiosPrivate.get(`/profile/${id}`);
                console.log(response.data);
                setData(response.data);
                const data = response.data
                setFriends(data.friends);
                setPossibleFriends(data.possibleFriends);
                setProfileViews(data.profileViews);
                setCredits(data.credits);
                setStarredUsers(data.starredUsers);
            } catch (error) {
                console.log(error);
            }
        }

        setTimeout(()=>{
            getUserData();
        }, 500)

    }, [])

    return (
        possibleFriends > 0 &&
            <Container className={styles.container}>
                <ProgressCard friends={friends} possibleFriends={possibleFriends} />
                <StatsRing profileViews={profileViews} credits={credits} role={role} />
                <Container display={'flex'} style={{ width: '100%', flexDirection: 'column' }} >
                    <h2 style={{ marginBottom: '0' }}>Starred Chats</h2>
                    <Table data={{
                        head: ['', ''],
                        body: starredUsers.map(user => [
                            <Group>
                                <Image src={user.picture} w={'50px'} h={'50px'} style={{ borderRadius: '50%' }} />
                                <Group style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <Text>{user.info1}</Text>   
                                    <Text>{user.info2}</Text>

                                </Group>

                            </Group>,
                            <Button onClick={() => handleEnterChat(id, user.friendId)}>Message</Button>])

                    }} />
                </Container>
            </Container>
        
    )
}

export default Dashboard
