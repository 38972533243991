import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from '../css/Connections.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import ProfilePicture from '../images/Visa.jpg';
import { MdChatBubbleOutline, MdInfoOutline, MdOutlinePersonAdd, MdCheck, MdExitToApp } from 'react-icons/md';


import Chart from './Chart';
import Loader from './Loader';
import { useNavigate, Link } from 'react-router-dom';

import { enterChat } from './handleEnterChat';

import useChat from '../hooks/useChat';
import { Button, Container, Grid, Group, Image, Modal, Overlay, SegmentedControl, Table, Tabs, TabsList, Text } from '@mantine/core';
import { UserCardImage } from './UserCard';
import ChatContext from '../context/ChatProvider';
import { useDisclosure } from '@mantine/hooks';


const Connections = ({ isSmall, setIsSmall }) => {

    const axiosPrivate = useAxiosPrivate();


    const { socket, myDbId, messages } = useChat();

    const USER = sessionStorage.getItem('user');
    const ID = sessionStorage.getItem('id');


    const navigate = useNavigate();

    const [option, setOption] = useState(sessionStorage.getItem('optionVal') || '0');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [friends, setFriends] = useState([]);
    const [possibleConnections, setPossibleConnections] = useState([]);
    const [sentFriendRequests, setSentFriendRequests] = useState([]);
    const [receivedFriendRequests, setReceivedFriendRequests] = useState([]);

    const [resolved, setResolved] = useState(false);

    const [opened, { open, close }] = useDisclosure(false);

    const [creditsModalOpened, setCreditsModalOpened] = useState(false);

    const [currentUser, setCurrentUser] = useState({});

    const [hasCredits, setHasCredits] = useState();

    const [credits, setCredits] = useState(0);

    const [actionType, setActionType] = useState();


    useEffect(() => {

        socket.emit('getUserSocials', ID);

        socket.on('userSocials', (data) => {
            setTimeout(() => {
                setFilteredUsers(option === '0' ? data.friends : option === '1' ? data.possibleConnections : option === '2' ? data.sentFriendRequests : data.receivedFriendRequests)
            }, 500)
            setFriends(data.friends);
            setPossibleConnections(data.possibleConnections);
            setSentFriendRequests(data.sentFriendRequests);
            setReceivedFriendRequests(data.receivedFriendRequests);
            setResolved(true);
            sessionStorage.setItem('optionVal', option);

        })

    }, [])

    useEffect(() => {
        users.length > 0 && setFilteredUsers(users.filter(user => user.friends?.includes(myDbId)));
        console.log(users);
    }, [users]);

    const handleOption = (optionVal) => {
        setOption(optionVal);
    }




    const handleFriendRequest = async (friendId) => {
        setCreditsModalOpened(true);
        socket.emit('friendRequest', { userId: ID, friendId: friendId });
        
    
        socket.on('hasCredits', (data)=>{
            if(data.hasCredits === true) {
                setHasCredits(true);
                setCredits(data.credits);
                setActionType('sent');

            } else {
                setHasCredits(false);
            }
        })
    }

    const handleAcceptRequest = async (friendId) => {
        setCreditsModalOpened(true);
       
        socket.emit('acceptFriendRequest', { userId: ID, friendId: friendId });

       

    }

    const handleEnterChat = (userDbId, friendId) => {

        enterChat(socket, userDbId, friendId);

        setTimeout(() => {
            navigate('/chat');
        }, 1000)


    }

    const noCreditsContent = <Container style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
        <Text w={'100%'} ta={'center'}>You have no credits currently. Get Some!</Text>
        <Button mt={'20px'} w={'50%'} onClick={() => navigate('/credits')}>Get Credits</Button>
    </Container>

    const hasCreditsContent = (actionType, credits) => {

        return <Container style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}    >
            <Text> You have successfuly {actionType} friend request! You have {credits} credits left! Need more? </Text>
            <Button mt={'20px'} onClick={() => navigate('/credits')}>Get Credits</Button>
        </Container>

    }


    useEffect(() => {

        switch (option) {
            case '0':
                setFilteredUsers(friends);
                break;
            case '1':
                setFilteredUsers(possibleConnections);
                break;
            case '2':
                setFilteredUsers(sentFriendRequests);
                break;
            case '3':
                setFilteredUsers(receivedFriendRequests);
                break;
        }

    }, [option])

    const handleInfoModal = (id) => {
        open();
        const myId = sessionStorage.getItem('id');
        socket.emit('getModalInfo', { id: id, myId: myId });

        socket.on('info', (data) => {
            setCurrentUser(data);
            console.log(data);
        })
    }

    const handleCloseModal = () => {
        close();
    }

    return (

        <Container>
            <h1>Connections</h1>
            <Container w={'100%'}>
                <Container m={0} p={0} className={styles.controlsContainer}>
                    <Tabs
                        w={'100%'}
                        value={option}
                        bg={'var(mantine-colors-mycolors-9)'}
                        onChange={(val) => handleOption(val)}
                        data={[
                            { label: 'Friends', value: '0' },
                            { label: 'Add Friends', value: '1' },
                            { label: 'Sent Friend Requests', value: '2' },
                            { label: 'Friend Requests', value: '3' },
                        ]}
                    >
                        <Tabs.List grow>
                            <Tabs.Tab value='0' className={styles.tab}>
                                Friends
                            </Tabs.Tab>
                            <Tabs.Tab value='1' className={styles.tab}>
                                Add
                            </Tabs.Tab>
                            <Tabs.Tab value='2' className={styles.tab}>
                                Sent
                            </Tabs.Tab>
                            <Tabs.Tab value='3' className={styles.tab}>
                                Requests
                            </Tabs.Tab>

                        </Tabs.List>
                    </Tabs>
                </Container>


                <Grid mt={'1em'}>
                    {filteredUsers.length > 0 ?
                        filteredUsers.map(user =>
                            <Grid.Col span={{ base: 12, xs: 4 }}><UserCardImage name={user.fullName} role={user?.profile?.position ? user.profile.position : 'Coach'} enterChat={handleEnterChat} myDbId={myDbId} userId={user.id} option={option} handleAcceptRequest={handleAcceptRequest} handleFriendRequest={handleFriendRequest} sentFriendRequests={sentFriendRequests} imageUrl={user.imageUrl} handleInfoModal={handleInfoModal} /></Grid.Col>) :

                        <Text ta={'center'} w={'100%'} mt={'sm'}>Nothing to display.</Text>}

                </Grid>

            </Container>
            <Modal className={styles.modal} style={{ borderRadius: '10px' }} size={'var(--size)'} opened={opened} onClose={() => handleCloseModal()} centered>
                {currentUser.role === 'player' ?
                    <Container display={'flex'} m={'0'} className={styles.modalContainer}>

                        <Container className={styles.mediaContainer} w={'50%'} display={'block'} style={{ justifyContent: 'space-between', marginBottom: '20px' }} >


                            <Image w={'100%'} mb={'20px'} style={{ borderRadius: '10px' }} src={`https://collegecentralbucket.s3.amazonaws.com/users/players/${currentUser.username}/${currentUser.profilePicture}`} />

                            <video width={'100%'} mb={'20px'} autoPlay={false} style={{ borderRadius: '10px' }} controls src={currentUser.playerProfile.video} />


                        </Container>

                        <Container w={'50%'} className={styles.tableContainer}>
                            <Table w={'100%'}>
                                <Table.Tbody>
                                    <Table.Tr>
                                        <Table.Td>Name</Table.Td>
                                        <Table.Td>{currentUser.fullName}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Age</Table.Td>
                                        <Table.Td>{currentUser.age}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Gender</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.gender === 'male' ? 'M' : 'F'}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Height | Weight</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.height}cm | {currentUser.playerProfile.weight}kg</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Nationality</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.nationality}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Position</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.position}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>{currentUser.playerProfile.sport === 'Soccer' ? 'Dominant Leg' : 'Dominang Hand'}</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.dominantSide}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Strengths</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.strengths.join(', ')}</Table.Td>
                                    </Table.Tr>
                                   
                                    <Table.Tr>
                                        <Table.Td>High School GPA</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.gpa}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Prefers to play in</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.preferredStates.join(', ')}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>School or sports</Table.Td>
                                        <Table.Td>{currentUser.playerProfile.schoolOrSports}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td>Budget</Table.Td>
                                        <Table.Td>${currentUser.playerProfile.preferredTuition}</Table.Td>
                                    </Table.Tr>

                                </Table.Tbody>
                            </Table>
                        </Container>






                    </Container>
                    : currentUser && currentUser.role === 'coach' ?
                        <Container display={'flex'} m={'0'} className={styles.modalContainer}>

                            <Container className={styles.mediaContainer} w={'50%'} display={'block'} style={{ justifyContent: 'space-between', marginBottom: '20px' }} >


                                <Image w={'100%'} mb={'20px'} style={{ borderRadius: '10px' }} src={`https://collegecentralbucket.s3.amazonaws.com/users/coaches/${currentUser.username}/${currentUser.profilePicture}`} />
                            </Container>

                            <Container w={'50%'} className={styles.tableContainer}>
                                <Table w={'100%'}>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Td>Name</Table.Td>
                                            <Table.Td>{currentUser.fullName}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td>Age</Table.Td>
                                            <Table.Td>{currentUser.age}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td>Gender</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.gender === 'male' ? 'M' : 'F'}</Table.Td>
                                        </Table.Tr>

                                        <Table.Tr>
                                            <Table.Td>Nationality</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.nationality}</Table.Td>
                                        </Table.Tr>

                                        <Table.Tr>
                                            <Table.Td>Sport</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.sport}</Table.Td>
                                        </Table.Tr>

                                        <Table.Tr>
                                            <Table.Td>Experience</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.experience} years</Table.Td>
                                        </Table.Tr>


                                        <Table.Tr>
                                            <Table.Td>Leadership Qualities</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.leadershipQualities.join(', ')}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td>Coaching Strategies</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.coachingStrategies.join(', ')}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td>Coaching Style</Table.Td>
                                            <Table.Td>{currentUser.coachProfile.coachingStyle.join(', ')}</Table.Td>
                                        </Table.Tr>


                                    </Table.Tbody>
                                </Table>
                            </Container>



                        </Container>
                        : null}
            </Modal>

            <Modal size={'lg'} opened={creditsModalOpened} onClose={() => setCreditsModalOpened(false)}>

                {hasCredits ?
                    hasCreditsContent(actionType, credits) :
                    noCreditsContent
                }

            </Modal>
        </Container >


    )
}

export default Connections