import React, { useContext, useEffect } from 'react'
import styles from '../css/Notification.module.css';
import ChatContext from '../context/ChatProvider';
import { MantineContext, Notification } from '@mantine/core';
const MyNotification = () => {

    
    const { notification } = useContext(ChatContext);
    return (
        

            <Notification withCloseButton={false} withBorder title={notification.title} className={styles.container} style={{display: notification.title ? 'flex' : 'none'}} >
                {notification.body}
            </Notification>


    )
}

export default MyNotification