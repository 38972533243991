import { Card, Group, Text, Menu, ActionIcon, Image, SimpleGrid, rem } from '@mantine/core';
import { IconDots, IconEye, IconFileZip, IconTrash } from '@tabler/icons-react';
import Chart from './Chart';



export const PlayerCard = ({ player, profilePicture, handleCurrentPlayer, viewImage }) => {

    return (
        <Card withBorder shadow="sm" radius="md" w={'400px'} m={'10px'}>
            <Card.Section withBorder inheritPadding py="xs">
                <Group justify="space-between">
                    <Text fw={500}>{player.fullName}</Text>
                    <Menu withinPortal position="bottom-end" shadow="sm">
                        <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                                <IconDots style={{ width: rem(16), height: rem(16) }} />
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item onClick={() => handleCurrentPlayer(player)} bg={'transparent'}>
                                View Details
                            </Menu.Item>

                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Card.Section>

            <Text mt="sm" c="dimmed" size="sm">

            </Text>

            <Card.Section mt="sm">
                <Image mah={'300px'} style={{objectPosition:'top left'}} m={'auto'} onClick={() => viewImage(player.profilePicture)} src={player.profilePicture} />
            </Card.Section>




            <Card.Section inheritPadding mt="sm" pb="md">
                <Text w={'100%'} ta={'center'}> {player.age} Years Old</Text>
                <Text w={'100%'} ta={'center'}>{player.playerProfile.position}</Text>
                <Text w={'100%'} ta={'center'}>{player.playerProfile.nationality}</Text>
            </Card.Section>


        </Card>
    );
}