import { useEffect, useState } from 'react';
import { Group, Code } from '@mantine/core';
import {
    IconSettings,
    IconLayoutDashboard,
    IconLogout,
    IconSchool,
    IconUser,
    IconHeartHandshake,
    IconTicket,
    IconLogin,
    IconRegistered

} from '@tabler/icons-react';

import { socket } from './socket';
import classes from '../css/NavbarSimple.module.css';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

import { GiSoccerKick } from "react-icons/gi";

const role = sessionStorage.getItem('role');

const data = [
    { link: '/dashboard', label: 'Dashboard', icon: IconLayoutDashboard },
    { link: '/profile', label: 'Profile', icon: IconUser },
    { link: '/colleges', label: 'Colleges', icon: IconSchool},
    { link: '/edit', label: 'Edit Colleges', icon: IconSchool},
    { link: '/connections', label: 'Connections', icon: IconHeartHandshake },
    { link: '/credits', label: 'Credits', icon: IconTicket },
    { link: '/settings', label: 'Settings', icon: IconSettings },
];

const data2 = [
    { link: '/dashboard', label: 'Dashboard', icon: IconLayoutDashboard },
    { link: '/profile', label: 'Profile', icon: IconUser },
    { link: '/players', label: 'Players', icon: GiSoccerKick},
    { link: '/connections', label: 'Connections', icon: IconHeartHandshake },
    { link: '/settings', label: 'Settings', icon: IconSettings },
];


export function NavbarSimple({ accessToken, toggle }) {
    const [active, setActive] = useState(sessionStorage.getItem('activeTab') || 'Dashboard');
    const { setAuth } = useAuth();
    /* TODO: REMOVE REFRESH TOKEN */
    const handleLogout = () => {
        setAuth({});
        socket.disconnect();
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('socketID');
        sessionStorage.removeItem('activeTab');
        window.location.href = '/signin';

    }

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'activeTab') {
                console.log(e.newValue);
                setActive(e.newValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [])

    const links = data.map((item) => (
        <Link
            className={classes.link}
            data-active={item.label === active || undefined}
            to={item.link}
            key={item.label}
            onClick={(event) => {
                setActive(item.label);
                sessionStorage.setItem('activeTab', item.label);
                toggle();
            }}
        >
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
        </Link>
    ));

    const links2 = data2.map((item) => (
        <Link
            className={classes.link}
            data-active={item.label === active || undefined}
            to={item.link}
            key={item.label}
            onClick={(event) => {
                setActive(item.label);
                toggle();
            }}
        >
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
        </Link>
    ));

    return (
        accessToken ?
            <nav className={classes.navbar} >
                <div className={classes.navbarMain}>

                    {role === 'player' ? links : links2}
                </div>

                <div className={classes.footer}>


                    <Link className={classes.link} onClick={(event) => handleLogout()}>
                        <IconLogout className={classes.linkIcon} stroke={1.5} />
                        <span>Logout</span>
                    </Link>
                </div>
            </nav> :

            <nav className={classes.navbar}>
                <div className={classes.navbarMain}>
                    <Group className={classes.header} justify="space-between">
                        {/*  <MantineLogo size={28} /> */}
                    </Group>
                    {links2}
                </div>

            </nav>



    );
}