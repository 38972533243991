export const schools = [
    "Arizona Christian University",
    "Westcliff University ",
    "Benedictine College",
    "Bluefield University (VA)",
    "Carroll College",
    "Central Christian College",
    "Embry-Riddle (Ariz.)",
    "Central Methodist University",
    "Clarke University",
    "College Of Idaho",
    "Culver-Stockton College",
    "Cumberland University",
    "Indiana East",
    "Florida Memorial (FL)",
    "Florida National (FL)",
    "Friends (KS)",
    "Hannibal-LaGrange University",
    "University of Houston - Victoria",
    "Huntington University",
    "Huston-Tillotson University",
    "Iowa Wesleyan University",
    "Judson University ",
    "Lawrence Tech University",
    "Life Pacific University",
    "Lindsey Wilson College",
    "Marian University Indianapolis",
    "University of Michigan - Dearborn",
    "Mid-America Christian University",
    "Midway University",
    "Ohio Christian University",
    "Oregon Institute of Technology",
    "Roosevelt University",
    "Southwestern Assemblies of God University",
    "University of Science & Arts of Oklahoma",
    "Southeastern University",
    "University of the Southwest",
    "St. Andrews University - North Carolina",
    "Taylor University",
    "Tennessee Wesleyan",
    "Texas College",
    "Texas Wesleyan University",
    "Thomas University",
    "Webber International University",
    "Viterbo University",
    "Park University",
    "Paul Quinn College",
    "Point Park University",
    "Providence Christian College ",
    "University of Antelope Valley",
    "Ave Maria University",
    "Avila University",
    "Bethel College",
    "Briar Cliff University",
    "Bryan College",
    "Bushnell University",
    "Cal Maritime",
    "Dalton State (GA)",
    "Campbellsville University",
    "Dordt (IA)",
    "Central Baptist College",
    "Columbia International University ",
    "Cleary University",
    "Evangel (MO)",
    "Columbia College",
    "Georgia Gwinnett College",
    "Indiana Nothwest ",
    "Goshen College",
    "Governors State University",
    "Grace College",
    "Graceland University",
    "Harris-Stowe State University",
    "Hope International University",
    "Indiana Wesleyan (IN)\t",
    "Indiana University",
    "Johnson University",
    "Kansas Wesleyan University",
    "Keiser University",
    "Madonna University",
    "McPherson College",
    "Menlo College",
    "MidAmerica Nazarene University",
    "Lourdes University",
    "Milligan University",
    "Missouri Valley College",
    "Mount Mercy University",
    "Northwestern College",
    "Oakland City University",
    "Oklahoma Wesleyan University",
    "Ottawa University",
    "Ottawa University - Surprise",
    "Our Lady of the Lake University",
    "Rochester University",
    "Rocky Mountain College",
    "Siena Heights University",
    "Southern Oregon University",
    "Saint Mary-of-the-Woods College",
    "Sterling College - Kansas",
    "Tabor College",
    "Tennessee Southern",
    "Texas A&M University-Texarkana",
    "Texas A&M University-San Antonio",
    "The Master's University",
    "Warner University",
    "Warner Pacific University",
    "Wayland Baptist University",
    "Trinity Christian College",
    "Williams Baptist University",
    "Xavier University",
    "York University",
    "University of Health Sciences & Pharmacy",
    "Pacific Union College",
    "University of Providence",
    "Aquinas Michigan",
    "Baker University",
    "Bethany College",
    "Bethel University",
    "Bethel (TN)",
    "Blue Mountain Christian (MS)",
    "Brescia University (KY)",
    "Brewton-Parker College (GA)",
    "Dakota Wesleyan (SD)",
    "Doane (NE)",
    "Evergreen (WA)",
    "Fisher (MA)",
    "Fisk (TN)",
    "Concordia University Ann Arbor",
    "Concordia University",
    "Corban University",
    "Cornerstone University",
    "University of the Cumberlands",
    "Florida College (FL)",
    "Freed-Hardeman (TN)",
    "Kentucky Christian",
    "Grand View University",
    "University of Jamestown ",
    "Jarvis Christian University",
    "Jessup University",
    "La Sierra University",
    "Life University",
    "Louisiana Christian University",
    "Middle Georgia State University",
    "Midland University",
    "LSU Shreveport ",
    "University of Mobile",
    "Montreat College",
    "Mount Marty University",
    "Northwest University",
    "Olivet Nazarene University",
    "University of Rio Grande",
    "University of Saint Francis - Indiana",
    "University of Saint Mary",
    "Shawnee State University",
    "Southwestern Christian University",
    "St. Ambrose University",
    "University of St. Francis - Illinois",
    "St. Thomas University - Florida",
    "Talladega College",
    "Walla Walla University",
    "Washington Adventist University",
    "Truett McConnell University",
    "William Carey University",
    "William Penn University",
    "Vanguard University",
    "William Woods University",
    "West Virginia University",
    "University of California",
    "University of Pikeville",
    "Benedictine University Mesa",
    "Bellevue University ",
    "Calumet College of Saint Joseph ",
    "Eastern Oregon",
    "Faulkner (AL)",
    "Georgetown College",
    "Indiana Tech",
    "Hastings College ",
    "Holy Cross College",
    "John Brown University",
    "LSU Alexandria",
    "Missouri Baptist University",
    "Morningside University",
    "Mount Vernon Nazarene University",
    "Multnomah University",
    "North American University",
    "Oakwood University",
    "Oklahoma City University",
    "Oklahoma Panhandle State University",
    "University of Saint Katherine",
    "Saint Xavier University",
    "Savannah College of Art and Design",
    "Simpson University",
    "Soka University of America",
    "Southwestern College - Kansas",
    "Spring Arbor University",
    "Waldorf University",
    "Wiley University",
    "Tougaloo College",
    "Union University",
    "University of Northwestern Ohio"
]



