import { Button, Container, Group, Input, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import React, { useState, useRef } from 'react'

import styles from '../css/Verify.module.css';
import axios from '../api/axios';
import { set } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
const Verify = () => {
    const [username, setUsername] = useState('');
    const [sent, setSent] = useState(false);

    const [val1, setVal1] = useState('');
    const [val2, setVal2] = useState('');
    const [val3, setVal3] = useState('');
    const [val4, setVal4] = useState('');
    const [val5, setVal5] = useState('');
    const [val6, setVal6] = useState('');

    const [code, setCode] = useState('');
    const [attempts, setAttempts] = useState(0);

    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();


    const handleSendCode = async () => {

        try {
            const response = await axios.post('/verify/sendCode', {username}, {
                headers: {
                    'Content-Type':'application/json'
                }
            })
    
            setSent(true);
            setErr('');
        } catch (error) {
            if(error.response.status === 403) {
                setErr('User with this email does not exist in our database. Please try a different email!')
            }
        }
    }

    const ref1= useRef();
    const ref2= useRef();
    const ref3= useRef();
    const ref4= useRef();
    const ref5= useRef();
    const ref6= useRef();

    useEffect(()=>{
        sent && ref1.current.focus();
    },[sent])


    useEffect(()=>{
        setCode(val1 + val2 + val3 + val4 + val5 + val6)
    },[val1, val2, val3, val4, val5, val6])


    const handleVerifyCode = async () => {
        if(attempts < 3) {
            setAttempts(attempts + 1);
            try {
                const response = await axios.post('/verify', {username, code}, {
                    headers: {
                        'Content-Type':'application/json'
                    }
                })
                setSuccess('Verification successful! Use this code to log in. You will be redirected to login page in 3 seconds.');
                setTimeout(()=>{
                    navigate('/signin');
                },3000)
            } catch (error) {
                if(error.response.status === 403) {
                    setErr(`Verification failed. You got ${3 - attempts} attempts left!`)
                }
            }
    
        } else {
            setErr('You are out of attempts! Please try again later. You will be redirecred to login page in 3 seconds.');
            setTimeout(()=>{
                navigate('/signin');
            },3000)
        }
        
      
    }


    return (
        <div style={{ padding: '10px', width: '100%', display: 'flex', alignSelf: 'center', margin: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

            {sent ?

                <Container w={'100%'} display={'flex'} style={{flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
                    <Text display={success ? 'none' : 'flex'}>Verification code has been sent to {username}</Text>
                    <h2 style={{display:success ? 'none' : 'initial'}}>Enter Verification Code</h2>

                    <Group display={success ? 'none' : 'flex'}>
                        <Input ta={'center'}  ref={ref1} value={val1} onChange={(e)=> {setVal1(e.currentTarget.value); ref2.current.focus()}} type='text' maxLength={1} w={'40px'} h={'40px'} m={'20px 10px'} />
                        <Input ref={ref2} maxLength={1} w={'40px'} h={'40px'} m={'20px 10px'} value={val2} onChange={(e)=> {setVal2(e.currentTarget.value); ref3.current.focus()}} />
                        <Input ref={ref3} maxLength={1} w={'40px'} h={'40px'} m={'20px 10px'}  value={val3} onChange={(e)=> {setVal3(e.currentTarget.value); ref4.current.focus()}}/>
                        <Input ref={ref4} maxLength={1} w={'40px'} h={'40px'} m={'20px 10px'}  value={val4} onChange={(e)=> {setVal4(e.currentTarget.value); ref5.current.focus()}} />
                        <Input ref={ref5} maxLength={1} w={'40px'} h={'40px'} m={'20px 10px'}  value={val5} onChange={(e)=> {setVal5(e.currentTarget.value); ref6.current.focus()}} />
                        <Input ref={ref6} maxLength={1} w={'40px'} h={'40px'} m={'20px 10px'} value={val6} onChange={(e)=>setVal6(e.currentTarget.value)} />
                       
                    </Group>

                    <Button display={success ? 'none' : 'flex'} disabled={code.length < 6 ? true : false} onClick={()=>handleVerifyCode()}>Verify</Button>
                    <Text color='red'>{err}</Text>
                    <Text c={'green'}>{success}</Text>
                    <Text display={success ? 'none' : 'flex'} mt={'10px'}>Didn't receive the code? <Link onClick={()=>handleSendCode()} >Resend email.</Link></Text>

                </Container> : 
                
                <Container w={'40%'} display={'flex'} style={{flexDirection:'column'}} >
                    <Text>Enter your email address</Text>
                    <Input placeholder='someone@example.com' value={username} onChange={(e)=>setUsername(e.currentTarget.value)} mt={'5px'} mb={'10px'}/>
                    <IconArrowRight className={styles.icon} onClick={()=>handleSendCode()} />
                    <Text mt={'10px'} color={'red'}> {err} </Text>
                </Container>

                }
            
        </div>

    )
}

export default Verify