import { Routes, Route, useNavigate, useLocation, Link, NavLink, Navigate, } from "react-router-dom";
import { useEffect, useState, useRef, useContext } from "react";

import { AppShell, Burger, Modal, Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { MdOutlineDashboard, MdPersonOutline, MdOutlineSchool } from "react-icons/md";
import { TbCirclesRelation } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FaPowerOff } from 'react-icons/fa6';

//Components
import Home from "./Home";
import Register from './Register';
import SignIn from './SignIn';

import RequireAuth from './RequireAuth';

import { ChatProvider } from "../context/ChatProvider";
import ChatContext from "../context/ChatProvider";

import { VideoChatProvider } from '../context/VideoChatProvider';
import VideoChatContext from "../context/VideoChatProvider";

import CallModal from "./CallModal";
import VideoChat from "./VideoChat";

import CollegesList from './CollegesList';
import useAuth from "../hooks/useAuth";
import Header from "./Header";
import Dashboard from "./Dashboard";
import EditCollege from "./EditCollege";
import AddFacility from "./AddFacility";
import Profile from "./Profile";
import Connections from "./Connections"
import Chat from './Chat';


/* Styling */
import '../css/index.css';
import styles from '../css/App.module.css';
import Logo from '../images/Logo-transparent.png'

import Notification from './MyNotification';
import { socket } from "./socket";
import { NavbarSimple } from "./NavbarSimple";
import Survey from "./Survey";
import Verify from "./Verify";
import Credits from "./Credits";
import PaymentSuccess from "./PaymentSuccess";
import Settings from "./Settings";
import Colleges from "./Colleges";
import { Footer } from "./Footer";
import { FeaturesGrid } from "./FeaturesGrid";
import { EmailBanner } from "./EmailBanner";
import { GetInTouch } from "./GetInTouch";
import NotFound from "./NotFound";
import Players from "./Players";



function App() {

  const { auth, setAuth } = useAuth();

  const sessionId = sessionStorage.getItem('sessionId') || '';


  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken'));
  const [isSmall, setIsSmall] = useState(false);
  const [currentId, setCurrentId] = useState('');

  const [hide, setHide] = useState(sessionStorage.getItem('hide') || false);

  const navigate = useNavigate();
  const location = useLocation();


  const { isModalOpen, notification } = useContext(ChatContext);
  const { callAccepted, callEnded, calling, receivingCall } = useContext(VideoChatContext);

  const [opened, { toggle }] = useDisclosure();
  const [path, setPath] = useState('')

  const [isOtp, setIsOtp] = useState(false);

  useEffect(() => {
    let path = location.pathname;
    if (path === '/') {
      navigate('/dashboard');
      setPath('/dashboard');
    } else {
      setPath(location.pathname)
    }
  }, [location.pathname])

  /* TODO: REMOVE REFRESH TOKEN */
  const handleLogout = () => {
    setAuth({});
    socket.disconnect();
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('socketID');

  }

  useEffect(() => {
    setAuth({ accessToken });
  }, [accessToken])

  useEffect(() => {
    if (location.pathname.includes('/addfacility') && currentId === '') {
      navigate('/edit');
    }
    if (location.pathname.includes('/editcollege') && currentId === '') {
      navigate('/edit');
    }

    console.log(isModalOpen);

  }, [])

  const playerRoutes = [

    {
      tag:
        <Link to={'/dashboard'} className={path === '/dashboard' ? styles.active : undefined} key={1}>
          <MdOutlineDashboard className={styles.icon} />
          <div className={styles.iconText}>
            Dashboard
          </div>
        </Link>
    },
    {
      tag:
        <Link to={'/profile'} className={path === '/profile' ? styles.active : undefined} key={2}>
          <MdPersonOutline className={styles.icon} />
          <div className={styles.iconText}>
            Profile
          </div>
        </Link>
    },
    {
      tag:
        <Link to={'/edit'} className={path === '/edit' ? styles.active : undefined} key={3}>
          <MdOutlineSchool className={styles.icon} />
          <div className={styles.iconText}>
            Colleges
          </div>
        </Link>
    },
    {
      tag:
        <Link to={'/connections'} className={path === '/connections' ? styles.active : undefined} key={4}>
          <TbCirclesRelation className={styles.icon} />
          <div className={styles.iconText}>
            Connections
          </div>
        </Link>
    },
    {
      tag:
        <Link to={'/credits'} key={5}>
          <MdOutlineDashboard className={styles.icon} />
          <div className={styles.iconText}>
            Credits
          </div>
        </Link>
    },
    {
      tag:
        <Link to={'/settings'} className={path === '/settings' ? styles.active : undefined} key={6}>
          <IoSettingsOutline className={styles.icon} />
          <div className={styles.iconText}>
            Settings
          </div>
        </Link>
    },

    {
      tag:
        <Link className={styles.li} key={7} >
          <FaPowerOff className={styles.icon} id='logoutIcon' onClick={() => handleLogout()} />
          <div htmlFor='logoutIcon' style={{ marginLeft: '0px' }} className={styles.iconText} onClick={() => handleLogout()} >
            Logout
          </div>
        </Link>
    }
  ]


  const [openedFeatures, setOpenedFeatures] = useState(false);
  const [openedBanner, setOpenedBanner] = useState(false);


  return (


    <AppShell
      header={{ height: 60 }}

      navbar={accessToken && isOtp === false ? {
        width: 200,
        breakpoint: 'sm',
        collapsed: { mobile: !opened, desktop: !opened },
      } : null}
      padding="md"


      footer={!accessToken && {
        width: '100%',
        height:200
      }}
    >

      <AppShell.Header bg={accessToken ? 'var(--mantine-color-myColors-9)' : '#242424'} display={'flex'} style={{ alignItems: 'center', justifyContent: 'space-between' }} className={'headerShell'}>

        <img src={Logo} style={{ height: '45px', width: '300px', maxWidth: '300px', }} />
        <Burger
          display={accessToken ? 'initial' : 'none'}
          opened={opened}
          onClick={toggle}
          size="sm"
          mr={'10px'}
        />
      </AppShell.Header>

      {accessToken && <AppShell.Navbar p="md" className="navbarShell"><NavbarSimple accessToken={accessToken} toggle={toggle} /></AppShell.Navbar>}


      <AppShell.Main style={{height:'100dvh'}} >

        <Notification />

        <Routes>
          <Route path="/getstarted" element={<Home hide={hide} setHide={setHide} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signin" element={<SignIn setIsOtp={setIsOtp} />} />
          <Route path="/verify" element={<Verify/>} />
          <Route path="/404" element={<NotFound/>}/>

          <Route path={'*'} element={<Navigate to={'/404'}/>} />

          {/* Sign in required to access these paths */}

          <Route element={<RequireAuth />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard isSmall={isSmall} setIsSmall={setIsSmall} />} />
            <Route path="/edit" element={<CollegesList isSmall={isSmall} setIsSmall={setIsSmall} currentId={currentId} setCurrentId={setCurrentId} />} />
            <Route path="/profile" element={<Profile isSmall={isSmall} setIsSmall={setIsSmall} />} />
            <Route path="/connections" element={<Connections isSmall={isSmall} setIsSmall={setIsSmall} />} />
            <Route path="/settings" element={<Settings  />} />

            <Route path={'/edit'} element={<CollegesList/>}/>
            <Route path={`/editcollege/${currentId}`} element={<EditCollege id={currentId} isSmall={isSmall} setIsSmall={setIsSmall} />} />
            <Route path={'/colleges'} element={<Colleges/>} />
            <Route path={'/players'} element={<Players/>} />
            <Route path={`/addfacility/${currentId}`} element={<AddFacility setId={setCurrentId} id={currentId} isSmall={isSmall} setIsSmall={setIsSmall} />} />
            <Route path="/chat" element={<Chat isSmall={isSmall} setIsSmall={setIsSmall} />} />
            <Route path="/credits" element={<Credits />} />

            <Route element={<RequireAuth />}>

              <Route path={`/success`} element={<PaymentSuccess />} />

            </Route>


          </Route>

        </Routes>
      
        {isModalOpen && <VideoChat />}</AppShell.Main>
        {!accessToken && <Footer setOpenedFeatures={setOpenedFeatures} setOpenedBanner = {setOpenedBanner} />}

        <Modal size={'80%'} opened={openedFeatures} onClose={()=>setOpenedFeatures(false)}>
          <FeaturesGrid/>
        </Modal>

        <Modal bg={'red'} size={'80%'} opened={openedBanner} onClose={()=>setOpenedBanner(false)}>
          <GetInTouch/>
        </Modal>
    </AppShell>

    /*  <div className="AppShell">
       
       <Header accessToken={accessToken} />
 
       <div className="AppMain">
 
       <NavbarSimple accessToken={accessToken} toggle={toggle} />
 
         <main>
         
         </main>
 
       </div>
 
     </div>
  */



  );
}

export default App;
