import React, { useEffect, useRef, useState } from 'react'
import appStyles from '../css/App.module.css';
import styles from '../css/Chat.module.css'
import Sidebar from './Sidebar';
import TextareaAutosize from 'react-textarea-autosize';
import { MdPersonOutline, MdOutlineAttachFile, MdOutlineEmojiEmotions, MdOutlineSend, MdStar, MdStarOutline } from 'react-icons/md';
import EmojiPicker from 'emoji-picker-react';
import { FaStar, FaVideo } from "react-icons/fa6";


import Loader from './Loader';

import VideoChat from './VideoChat';
import CallModal from './CallModal';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import ChatContext from '../context/ChatProvider';
import VideoChatContext from '../context/VideoChatProvider';
import { socket } from './socket';
import { Container, Group, Image, Modal, Table } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
const Chat = ({ isSmall, setIsSmall }) => {

    const { message, setMessage, messages, activity, friend, friendName, isSetPicker, setIsSetPicker, userName,  sendMessage, handleActivity, onEmojiClick, setIsModalOpen, friendId, socket } = useContext(ChatContext);

    const chatId = sessionStorage.getItem('chatId');

    const { setCalling } = useContext(VideoChatContext);
    // Handling Modal

    const [friendProfile, setFriendProfile] = useState({});
    const [friendPicture, setFriendPicture] = useState('');
    const [table, setTable] = useState();
    const [opened, { open, close }] = useDisclosure(false);
    const [starredChats, setStarredChats] = useState();

    const navigate = useNavigate();

    const user = sessionStorage.getItem('user');
    const id = sessionStorage.getItem('id');

    const inputRef = useRef(null);

    const lastMessage = useRef();

    useEffect(() => {
        lastMessage.current.scrollIntoView({ behavior: 'smooth' });
    }, [messages])


    useEffect(() => {

        socket.emit('getFriendProfile', {friendId:friendId, myId:id});
        socket.on('friendProfile', ({ friend, picture, starredChats }) => {

            setStarredChats(starredChats);

            setFriendProfile(friend);
            setFriendPicture(picture);

            console.log(friend, picture);
        })
    }, [])

    
    useEffect(() => {

        friendProfile && friendProfile.role === 'player' ?

            setTable(<Container w={'100%'} display={'flex'} m={'0'} className={styles.modalContainer}>

                <Container  className={styles.mediaContainer} display={'block'} style={{ justifyContent: 'space-between', marginBottom: '20px' }} >


                    <Image w={'100%'} mb={'20px'} style={{ borderRadius: '10px' }} src={friendPicture} />

                    <video width={'100%'} mb={'20px'} autoPlay={false} style={{ borderRadius: '10px' }} controls src={friendProfile.playerProfile.video} />


                </Container>

                <Container w={'50%'} className={styles.tableContainer}>
                    <Table w={'100%'}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>Name</Table.Td>
                                <Table.Td>{friendProfile.fullName}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Age</Table.Td>
                                <Table.Td>{friendProfile.age}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Gender</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.gender === 'male' ? 'M' : 'F'}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Height | Weight</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.height}cm | {friendProfile.playerProfile.weight}kg</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Nationality</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.nationality}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Position</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.position}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>{friendProfile.playerProfile.sport === 'Soccer' ? 'Dominant Leg' : 'Dominang Hand'}</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.dominantSide}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Strengths</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.strengths.join(', ')}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Аreas for improvement</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Injury history</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>High School GPA</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.gpa}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Prefers to play in</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.preferredStates.join(', ')}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>School or sports</Table.Td>
                                <Table.Td>{friendProfile.playerProfile.schoolOrSports}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Budget</Table.Td>
                                <Table.Td>${friendProfile.playerProfile.preferredTuition}</Table.Td>
                            </Table.Tr>

                        </Table.Tbody>
                    </Table>
                </Container>






            </Container>) : 
            friendProfile.role === 'coach' ?

               setTable(<Container display={'flex'} m={'0'} className={styles.modalContainer}>

                    <Container className={styles.mediaContainer} m={'0'}  display={'block'} style={{ justifyContent: 'space-between', marginBottom: '20px' }} >
                        <Image w={'100%'} mb={'20px'} style={{ borderRadius: '10px' }} src={friendPicture} />
                    </Container>

                    <Container  m={'0'} className={styles.tableContainer}>
                        <Table w={'100%'}>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Table.Td>Name</Table.Td>
                                    <Table.Td>{friendProfile.fullName}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Age</Table.Td>
                                    <Table.Td>{friendProfile.age}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Gender</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.gender === 'male' ? 'M' : 'F'}</Table.Td>
                                </Table.Tr>

                                <Table.Tr>
                                    <Table.Td>Nationality</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.nationality}</Table.Td>
                                </Table.Tr>

                                <Table.Tr>
                                    <Table.Td>Sport</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.sport}</Table.Td>
                                </Table.Tr>

                                <Table.Tr>
                                    <Table.Td>School</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.school}</Table.Td>
                                </Table.Tr>



                                <Table.Tr>
                                    <Table.Td>Experience</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.experience} years</Table.Td>
                                </Table.Tr>


                                <Table.Tr>
                                    <Table.Td>Leadership Qualities</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.leadershipQualities.join(', ')}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Coaching Strategies</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.coachingStrategies.join(', ')}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>Coaching Style</Table.Td>
                                    <Table.Td>{friendProfile.coachProfile.coachingStyle.join(', ')}</Table.Td>
                                </Table.Tr>


                            </Table.Tbody>
                        </Table>
                    </Container>



                </Container>) : 
                
                <Container></Container>;

    }, [friendProfile])


    const starChat  = () => {
        const id = sessionStorage.getItem('id')

        socket.emit('starChat', {id, chatId});
       
    }

    const unstarChat  = () => {
        const id = sessionStorage.getItem('id')
        socket.emit('unstarChat', {id, chatId});
      
    }


    useEffect(()=> {
        socket.on('chatStarred', ({starredChats}) => {
            console.log(starredChats);
            setStarredChats(starredChats)
        })
        socket.on('chatUnstarred', ({starredChats}) => {
            console.log(starredChats);
            setStarredChats(starredChats)
        })
    },[]);



    return (


        <div>
            {!messages && <Loader />}
            <div className={styles.chat} style={{ '--emojisWidth': isSetPicker ? 'initial' : 'none' }}>
                <div style={{display:'flex', justifyContent:'space-between'}} >


                    <div   style={{ display:'flex', width:'80%',justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <Image src={friendPicture} onClick={() => open()} w={'40px'} h={'40px'} mr={'10px'} style={{ borderRadius: '50%', cursor:'pointer' }} />
                        <div>
                            <div className={styles.friendName}>{friendName}</div>
                            <div className={styles.friendName}>{activity ? 'Typing...' : ''}</div>

                        </div>
                    </div>

                    <div  style={{ display:'flex', width:'80%',justifyContent: 'flex-end', alignItems: 'center' }}>
                        {starredChats && starredChats.includes(chatId) ? 
                        <MdStar className={styles.videoIcon} onClick={()=> unstarChat()}/>  : <MdStarOutline className={styles.videoIcon} onClick={()=>starChat()}/> }
                        <FaVideo className={styles.videoIcon} onClick={() => { setIsModalOpen(true) }} />
                    </div>





                </div>
                <div className={styles.chatContainer}>

                    {messages && messages.map(msg =>

                        <div className={styles.row} key={messages.indexOf(msg)}>
                            <div className={msg.from === userName ? styles.userBubble : styles.friendBubble}>
                                <div className={styles.message}>
                                    {msg.message}
                                </div>
                                <div className={styles.time}>
                                    {msg.time}
                                </div>
                            </div>
                        </div>
                    )}
                    <div ref={lastMessage}></div>
                </div>

                <div id='sendContainer' className={styles.sendContainer} >
                    <MdOutlineEmojiEmotions className={styles.controlIcon} onClick={() => setIsSetPicker(!isSetPicker)} />
                    <div className={styles.emojiPickerContainer} style={{ display: isSetPicker ? 'flex' : 'none' }}>
                        <EmojiPicker searchDisabled={true} skinTonesDisabled={true} className={styles.EmojiPickerReact} onEmojiClick={(EmojiObject, event) => onEmojiClick(EmojiObject, event)} width={'500px'} />
                    </div>
                    {<TextareaAutosize ref={inputRef} id='message' placeholder="Send a message" maxRows={3} value={message.toString()} onChange={(e) => handleActivity(e.target.value)} />
                    }
                    <MdOutlineSend className={styles.controlIcon} style={{ rotate: '-15deg' }} onClick={() => sendMessage()} />

                </div>

            </div>

            <Modal opened={opened} onClose={close} size={'lg'}>
                {table}
            </Modal>
        </div>




    )
}

export default Chat