import { Container, Text, Button, Group } from '@mantine/core';
import { GithubIcon } from '@mantinex/dev-icons';
import classes from '../css/HeroContentLeft.module.css';
import Header from './Header';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Footer } from './Footer';
const Home = ({hide, setHide}) => {


    const accessToken = sessionStorage.getItem('accessToken')

    useEffect(()=>{
        !accessToken && setHide(false);
    },[])
    
    const navigate = useNavigate();
    const handleNavigate = () => {
        setHide(true);
        navigate('/signin');
        sessionStorage.setItem('hide', true);
    }
    const navigateToSurvey = (role) => {
        setHide(true);
        navigate('/register');
        sessionStorage.setItem('hide', true);
        sessionStorage.setItem('role', role);
    }


    return (
        <div className={classes.wrapper} style={{display: !accessToken && !hide ? 'block' : 'none'}} >
            <Header />
            <Container w={'100%'} className={classes.inner}>
                <h2 className={classes.title}>

                    <Text component="span" variant="gradient" gradient={{ from: 'var(--mantine-color-myColors-9)', to: 'var(--mantine-color-myColors-4)' }} inherit>
                        CollegeCentral <br />
                    </Text>
                    Your Playbook for Athletic and Academic Success
                    <br />
                    <br />
                </h2>

                <Text className={classes.description} c="dimmed">
                    Welcome to CollegeCentral, where athletic excellence meets academic achievement. Unleash your potential and connect with coaches worldwide who recognize your talent. Fuel your ambition, elevate your game. This is your playbook for success. Get started now.
                </Text>

                <Group className={classes.controls}>
                     <Button
                        size="xl"
                        className={classes.control}
                        bg={'var(--mantine-color-myColors-9)'}
                        onClick={()=>navigate('/register')}
                    >
                        Get started
                    </Button>
                
                </Group>
                <br />
                 <Text  className={classes.description} >
                    Already enrolled? <label style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleNavigate()}>Log in</label>
                </Text> 
            </Container>
        </div>
    )
}

export default Home
